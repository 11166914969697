import React, { Component } from 'react';
import superagent from 'superagent';

class Speedport extends Component {
  state = {
    speedportIp: null,
    data: {},
    speedportInfos: {},
    error: false
  };

  componentDidMount() {
    const {
      data,
      ipAdress
    } = this.props;

    if (data && ipAdress) {
      superagent.get(`http://${ipAdress}/rest`)
      .end((err, res) => {
        if (err) {
          this.setState({
            error: err
          });

          return;
        }

        this.setState({
          speedportIp: ipAdress,
          speedportInfos: res.body,
          data
        });
      });

    }
  }

  render() {
    return <div className='speedportDiv'>
      <a target='_BLANK' href='http://speedport.ip/'>
        Tel-Speedp
      </a>
    </div>;
  }
}

export default Speedport;