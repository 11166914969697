import React, { Component } from 'react';
import moment from 'moment';
import superagent from 'superagent';
import Chart from 'react-apexcharts';

import RequestHint from '../../Atoms/hint';
import Typography from '../../Atoms/typography';
import LoaderGraph from '../../Atoms/loaderGraphQl';
import ErrorModal from '../Modals/errorModal';

import {
  parseAsNumber,
  average,
  count,
  sum,
  min,
  max
} from '../../../Utils/math';

const hourMinuteFormat = 'HH:mm'

class TimeSeriesChart extends Component {
  state = {
    componentHeight: 0,
    componentWidth: 0,
    jsonWebToken: null,
    calculateBy: 'average',
    componentId: '',
    loading: true,
    error: null,
    data: [],
    time: Date.now()
  }

  loadData(url) {
    const {
      REACT_APP_PRESENTER_API
    } = process.env;

    const jsonWebToken = localStorage.getItem('jsonWebToken');

    if (jsonWebToken) {
      superagent.get(`${REACT_APP_PRESENTER_API}${url}`)
      .set('Authorization', `Bearer ${jsonWebToken}`)
      .end((err, res) => {
        if (err) {
          this.setState({
            error: err.message,
            loading: false
          });

          return;
        }

        this.setState({
          data: res.body,
          loading: false
        });
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    let jsonWebToken = localStorage.getItem('jsonWebToken');

    if (!jsonWebToken) {
      this.setState({
        loading: false,
        error: 'You are not logged in....'
      });
    }

    if (jsonWebToken) {
      this.setState({
        jsonWebToken
      }, () => {
        this.loadData(nextProps.dataUrl);
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  createHourMinutesArray() {
    let from = moment().subtract(1, 'hour'); 
    const to = moment(); 

    const result = [];

    while (from.isBefore(to)) {
      from = from.add(1, 'minute');
      result.push(moment(from));
    }

    return result.map(h => h.format(hourMinuteFormat));
  }

  componentDidMount() {
    const width = this.divElement.clientWidth;
    const height = this.divElement.clientHeight;

    this.interval = setInterval(() => this.setState(
      { time: Date.now() },
      () => this.loadData(this.props.dataUrl)
    ), 30000);

    let jsonWebToken = localStorage.getItem('jsonWebToken');

    if (!jsonWebToken) {
      this.setState({
        loading: false,
        error: 'You are not logged in....'
      });
    }

    if (jsonWebToken) {
      this.setState({
        componentWidth: width,
        componentHeight: height,
        componentId: 'LineChart',
        jsonWebToken: jsonWebToken,
        loading: true
      }, () => {
        this.loadData(this.props.dataUrl)
      });
    }
  }

  transformData(data) {
    const {
      separateBy,
      showBy,
      values
    } = this.props;
    const {
      calculateBy
    } = this.state;
    let preSeries = {};
    let middleSeries = {};

    const series = [];
    const hourMinutesArray = this.createHourMinutesArray();

    Object.keys(data).forEach(key => {
      if (!preSeries[data[key][separateBy]]) {
        preSeries[data[key][separateBy]] = {};
      }

      const hourMinute = moment(data[key][showBy]).format(hourMinuteFormat);

      if (!preSeries[data[key][separateBy]][hourMinute]) {
        preSeries[data[key][separateBy]][hourMinute] = [];
      }

      preSeries[data[key][separateBy]][hourMinute].push(
        parseAsNumber(data[key][values])
      );
    });

    Object.keys(preSeries).forEach(keyName => {
      console.log(preSeries[keyName])


      if (!middleSeries[keyName]) {
        middleSeries[keyName] = [];
      }

      hourMinutesArray.forEach(key => {
        let value = 0;

        
        if (preSeries[keyName][key]) {
          if (calculateBy === 'average') {
            value = average(preSeries[keyName][key]);
          }
          if (calculateBy === 'sum') {
            value = sum(preSeries[keyName][key]);
          }
          if (calculateBy === 'min') {
            value = min(preSeries[keyName][key]);
          }
          if (calculateBy === 'max') {
            value = max(preSeries[keyName][key]);
          }
          if (calculateBy === 'count') {
            value = count(preSeries[keyName][key]);
          }
        }
        
        middleSeries[keyName].push(value)
      });
    });

    Object.keys(middleSeries).forEach(keyName2 => {
      series.push({
        name: keyName2,
        data: middleSeries[keyName2]
      })
    });

    return {
      categories: hourMinutesArray,
      series
    };
  }

  render() {
    const {
      componentWidth,
      componentId,
      calculateBy,
      loading,
      error,
      data,
      time
    } = this.state;
    let {
      type
    } = this.props;

    let returnElements = <LoaderGraph />;

    if (!loading && !error && data.length === 0) {
      returnElements = <RequestHint
      text={'Nothing to show. Are your Runners running?'}
      type='info'
      />;
    }

    if (error) {
      returnElements = <ErrorModal
      errorMessage={`Error: 😒 ${error}`}
      />;
    }

    if (!error && !loading && data.length > 0) {
      const dataObj = this.transformData(data);

      const options = {
        chart: {
          height: 350,
          type: type,
          zoom: {
            enabled: false
          },
          animations: {
            enabled: false
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          // dashArray: 2
        },
        xaxis: {
          // type: 'number',
          categories: dataObj.categories
        },
        yaxis: {
          labels: {
            show: true,
            formatter: (value) => { return parseFloat(value).toFixed(2) },
          }
        },

        legend: {
          show: true,
          fontSize: '10px',
        }
      };

      returnElements = <Chart
        options={options}
        series={dataObj.series}
        type={type}
        height={350}
        width={componentWidth}
        style={{float: 'none', clear: 'both'}}
      />;
    }

    return (
      <div
        id={componentId}
        ref={(divElement) => { this.divElement = divElement }}
        style={{
          height: '100%',
          position: 'relative',
          // display: 'flex'
        }}
      >
        <div style={{float: 'none', clear: 'both'}}>
          <Typography component='label'>
            Last Fetch: {new Date(time).toLocaleDateString()} {new Date(time).toLocaleTimeString()}
          </Typography>
        </div>

        <select
        onChange={(e) => this.setState({calculateBy: e.target.value})}
        >
          <option value='average'>Average</option>
          <option value='sum'>Sum</option>
          <option value='min'>Min</option>
          <option value='max'>Max</option>
          <option value='count'>Count</option>
        </select>


        {/* <div
        style={{float: 'none', clear: 'both'}}
        onClick={() => this.setState({calculateBy: 'sum'})}
        >

          <Typography
          component='label'
          >
            
            {calculateBy}
          </Typography>
        </div> */}
        <br/>
        { returnElements }
      </div >
    );
  }
}

export default TimeSeriesChart;
