import React, { Component } from 'react';

import KanyeRest from '../../Plugins/kanyeRest';
import Grid from '../../Atoms/grid';
import AppHeading from '../../Atoms/appHeading';
import Navigation from '../../Molecules/navigation';
import Card from '../../Molecules/card';
import DefaultTemplate from '../../Templates/defaultTemplate';

export default class WelcomePage extends Component {
  state = {
    selectedPage: false
  };

  render() {
    const {
      REACT_APP_APPLICATION_NAME
    } = process.env;

    // TODO: Add Page Model
    return (
      <DefaultTemplate>
        <AppHeading text={REACT_APP_APPLICATION_NAME}/>
        {/* <Grid container>
          <Navigation/>
        </Grid> */}

        <Grid container>
          <Grid columns={3} spacing={10}>
            <Card
              title='Dashboards'
              description={`
                Go to the Dashboard section 
                to explore the data defined in the dashboard 
                frontend. 
                You can define your own statistics inside your presenter api and view them here.
              `}
              url='/dashboard'
              icon='dashboard'
              status='in_development'
            />
          </Grid>
          <Grid columns={3} spacing={10}>
            <Card
              title='Home Network'
              description={`
                Control your network devices here.
                Make sure having nmap installed.
                First connector will be implemented for lights and a router (Hue/Speedport)
              `}
              url='/home-network'
              icon='home-network'
              status='live'
            />
          </Grid>
          {/* <Grid columns={2} spacing={10}>
            <Card
              title='Explorer'
              description={`
                Control your network devices here.
                Make sure having nmap installed.
                First connector will be implemented for lights and a router (Hue/Speedport)
              `}
              url='/explore'
              icon='network'
              status='in_development'
            />
          </Grid> */}
          <Grid columns={3} spacing={10}>
            <Card
              title='News'
              description={`
                A place for your news.
                Connect your News APIs and show them here. 
                To analyze and scrape your news apis, just make sure you have a news data model registered. 
              `}
              url='/news'
              icon='news'
              status='in_development'
            />
          </Grid>
          <Grid clear/>

          {/* <Grid spacing={10}>
            <KanyeRest />
          </Grid> */}

          <Grid columns={12} spacing={10}>
            <Card
              title='Component Library'
              description={`
                Checkout the Component Library for your new Dashboard
              `}
              url='/component-library'
              status='in_development'
            />
          </Grid>

        </Grid>
        <Grid clear/>
      </DefaultTemplate>
    );
  }
};
