import React, { Component } from 'react';

import Typography from '../Atoms/typography';
import PhilipsHue from '../Molecules/philipsHue';
import Speedport from '../Molecules/speedport';

import '../../Assets/Stylesheets/_card.scss';

export default class PluginCard extends Component {
  getPlugin() {
    const {
      plugin,
      data,
      ipAdress
    } = this.props;

    switch(plugin) {
      case 'philipsHue':
        return <PhilipsHue key='deviceCardHue' ipAdress={'192.168.2.100'} data={data}/>;
      case 'speedport':
        return <Speedport key='deviceCardSpeedport' ipAdress={ipAdress} data={data}/>;
      default:
        return `${plugin} ${JSON.stringify(data)}`;
    }
  }

  render() {
    const {
      plugin
    } = this.props;

    return (
      <div className='deviceCard' key='deviceCard'>
        <Typography key='deviceCardTypo' component='h1' textAlign='center'>
          {plugin.toUpperCase()}
        </Typography>
        {this.getPlugin()}
      </div>
    );
  }
}
