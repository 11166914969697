
import React from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '../../Atoms/grid';
import KpiNumber from '../../Atoms/kpiNumber';
import Card from '../../Molecules/card';
import ChartCard from '../../Molecules/card';
import DataTable from '../../Molecules/dataTable';
import TimeSeriesChart from '../../Molecules/Charts/timeSeriesChart';

function DevicesDashboard() {
  const rootstyle = {
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    boxSizing: 'border-box',
  }

  const dataTypeMapping = {
    id: 'integer',
    producer_ips: 'info',
    device_names: 'info',
    producer_names: 'info',
    // device_types: 'info',
    // device_networks: 'info',
    // device_locations: 'info',
    cpu_loads_average_load_percentages: 'integer',
    cpu_loads_current_load_percentages: 'integer',
    cpu_loads_current_load_user_percentages: 'integer',
    cpu_loads_current_load_system_percentages: 'integer',
    cpu_loads_current_load_idle_percentages: 'integer',
    ram_usages_total_bytes: 'integer',
    ram_usages_used_bytes: 'integer',
    ram_usages_free_bytes: 'integer',
    hdd_usages_total_bytes: 'integer',
    hdd_usages_used_bytes: 'integer',
    hdd_usages_free_bytes: 'integer',
    posted_ats: 'dateTime',
  };

  return (
    <div style={rootstyle}>
      <Grid container>
        <Grid
          columns={2}
          spacing={10}
        >
          <ChartCard
          title='Devices'
          description='registered devices'
          >
            <KpiNumber
            countDomain='devices-dp-links-dp-producer-names/count'
            title= 'devices'
            />
          </ChartCard>
        </Grid>
        <Grid
          columns={2}
          spacing={10}
        >
          <ChartCard
          title='IPs'
          description='counts all registered ips'
          >
            <KpiNumber
            countDomain='devices-dp-links-dp-producer-ips/count'
            title= 'ips'
            />
          </ChartCard>
        </Grid>

        <Grid
          columns={12}
          spacing={10}
        >
          <ChartCard
          title='Metrics'
          description='counts all device metrics pushed'
          >
            <KpiNumber
            countDomain='devices-dp-metrics-dp-totals/count'
            title= 'metrics'
            />
          </ChartCard>
        </Grid>
        <Grid
          columns={2}
          spacing={10}
        >
          <ChartCard
          title='Free Ram by Machine last 60 mins'
          description='shows the free ram per device in gigabytes by machine'
          >
            <TimeSeriesChart
            dataUrl = '/views-devices/metrics-last-hour'
            values = 'ram_usage_free_gigabytes'
            separateBy='computers_name'
            showBy='posted_ats'
            type='bar'
            />
          </ChartCard>
        </Grid>
        <Grid
          columns={2}
          spacing={10}
        >
          <ChartCard
          title='Used Ram by Machine last 60 mins'
          description='shows the used ram per device in gigabytes by machine'
          >
            <TimeSeriesChart
            dataUrl = '/views-devices/metrics-last-hour'
            values = 'ram_usage_used_gigabytes'
            separateBy='computers_name'
            showBy='posted_ats'
            type='bar'
            />
          </ChartCard>
        </Grid>
        <Grid
          columns={2}
          spacing={10}
        >
          <ChartCard
          title='Free Diskspace by Machine last 60 mins'
          description='shows the free hdd size per device in gigabytes by machine'
          >
            <TimeSeriesChart
            dataUrl = '/views-devices/metrics-last-hour'
            values = 'hdd_usage_free_gigabytes'
            separateBy='computers_name'
            showBy='posted_ats'
            type='bar'
            />
          </ChartCard>
        </Grid>
        <Grid
          columns={2}
          spacing={10}
        >
          <ChartCard
          title='Cpu Usages by Machine last 60mins'
          description='current cpu usage in percent on tracked servers'
          >
            <TimeSeriesChart
            dataUrl = '/views-devices/metrics-last-hour'
            values = 'current_cpu_load_percentages'
            separateBy='computers_name'
            showBy='posted_ats'
            type='bar'
            />
          </ChartCard>
        </Grid>
        <Grid
          columns={12}
          spacing={10}
        >
          <Card
            title='Last 100 Visits'
          >
            <DataTable
              dataTypeMapping={dataTypeMapping}
              url={'views-devices/all'}
            />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default withRouter(
  DevicesDashboard
);
