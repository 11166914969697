import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import ApolloClient, { HttpLink, InMemoryCache } from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';

import Home from './Components/Pages/Home';
import News from './Components/Pages/News';
import Explorer from './Components/Pages/Explorer';
import Reports from './Components/Pages/Reports';
import Dashboard from './Components/Pages/Dashboard';
import HomeNetwork from './Components/Pages/HomeNetwork';
import ExplorerDomain from './Components/Pages/ExplorerDomain';
import ComponentLibrary from './Components/Pages/ComponentLibrary';
import ExplorerDomainGroup from './Components/Pages/ExplorerDomainGroup';
import ExplorerDomainGroupTable from './Components/Pages/ExplorerDomainGroupTable';
import DashboardDomain from './Components/Pages/DashboardDomain';
import NotFound from './Components/Pages/NotFound';

const {
  REACT_APP_PRESENTER_API
} = process.env;
// Pass your GraphQL endpoint to uri

const jsonWebToken = localStorage.getItem('jsonWebToken');

const client = new ApolloClient({
  uri: `${REACT_APP_PRESENTER_API}/graphql`,
  cache: new InMemoryCache({
    addTypename: false
  })
});

export default function Routes() {
  return (
    <ApolloProvider client={client}>
      <Router>
        {/*
            A <Switch> looks through all its children <Route>
            elements and renders the first one whose path
            matches the current URL. Use a <Switch> any time
            you have multiple routes, but you want only one
            of them to render at a time
          */}
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/reports">
            <Reports />
          </Route>
          <Route exact path="/dashboard">
            <Dashboard />
          </Route>
          <Route exact path="/home-network">
            <HomeNetwork/>
          </Route>
          <Route exact path="/component-library">
            <ComponentLibrary/>
          </Route>
          <Route exact path="/news">
            <News/>
          </Route>
          <Route exact path="/dashboard/:domain">
            <DashboardDomain />
          </Route>
          <Route exact path="/explore">
            <Explorer />
          </Route>
          <Route exact path="/explore/:domain">
            <ExplorerDomain/>
          </Route>
          <Route exact path="/explore/:domain/:group">
            <ExplorerDomainGroup/>
          </Route>
          <Route exact path="/explore/:domain/:group/:table">
            <ExplorerDomainGroupTable/>
          </Route>
          <Route exact path="*">
            <NotFound />
          </Route>
        </Switch>
      </Router>
    </ApolloProvider>
  );
}
