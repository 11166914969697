import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import superagent from 'superagent';

import {
  setQueryParam,
} from '../../Utils/query';

import '../../Assets/Stylesheets/_form.scss'

import Grid from '../Atoms/grid';
import Typography from '../Atoms/typography';

import {
  capitalize,
  capitalizeAll
} from '../../Utils/string';

class FilterSelect extends Component {
  constructor(props) {
    super(props);

    this.setQueryParam = setQueryParam.bind(this);
  }

  state = {
    showToggle: false,
    responseArray: []
  }

  filterResponse(object) {
    const {
      keyName
    } = this.props;
    const returnArray = [];
    let cleanedObject = {};

    Object.keys(object).forEach(key => {
      cleanedObject[object[key][keyName]] = object[key];
    });

    Object.keys(cleanedObject).forEach(key => {
      if(!returnArray.includes(cleanedObject[key][keyName])) {
        returnArray.push(
          <div
          key={`links_dp_${keyName}`}
          onClick={() => this.setQueryParam({[`links_dp_${keyName}`]: cleanedObject[key][`links_dp_${keyName}`]})}
          >
            {cleanedObject[key][keyName]}
          </div>
        );
      }
    });

    return returnArray;
  }

  searchApi(value) {
    const {
      keyName,
      pathname
    } = this.props;
    const {
      REACT_APP_PRESENTER_API
    } = process.env;
    let jsonWebToken = localStorage.getItem('jsonWebToken');

    if (!jsonWebToken) {
      this.setState({
        loading: false,
        responseArray: ['You are not logged in....']
      });
    }

    if (jsonWebToken) {
      superagent.get(`${REACT_APP_PRESENTER_API}/${pathname}?${keyName}_contains=${value}`)
      .set('Authorization', `Bearer ${jsonWebToken}`)
      .end((err, res) => {
        if (err) {
          this.setState({
            responseArray: [err.message],
            loading: false
          });

          return;
        }

        if (res.body.error) {
          this.setState({
            responseArray: [res.body.error],
            loading: false
          });

          return;
        }

        this.setState({
          responseArray: this.filterResponse(res.body)
        });
      });
    }

  }

  toggleDiv(results) {
    const returnArray = [];

    results.map(key => {
      returnArray.push(
        <div className='inputSearchResults'>
          {key}
        </div>
      );
    })

    return returnArray;
  }

  render () {
    const {
      keyName,
      pathname
    } = this.props;
    const {
      showToggle,
      responseArray
    } = this.state;

    return (
      <Grid
      spacing={10}
      >
        <div className='label'>
          <Typography
            component='label'
            textAlign='center'
          >
            {capitalizeAll(keyName)}
          </Typography>
        </div>
        <input
        onFocus={(e) => this.setState({
          showToggle: true
        })}
        // onBlur={(e) => this.setState({
        //   showToggle: false
        // })}
        onChange={(e) => this.searchApi(
          e.target.value
        )}
        />
        {showToggle ? this.toggleDiv(responseArray) : null}
      </Grid>
    );
  }
};

export default withRouter(FilterSelect);