import React, { Component } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

export default class Loader extends Component {
  render () {
    return (
      <div style={{
        width: '100%',
        textAlign: 'center'
      }}>
        <CircularProgress
        style={{
          height: '69px',
          width: '69px',
        }}
        disableShrink
        />
      </div>
    );
  }
};
