import React,  { Component } from 'react';
import superagent from 'superagent';


import {
  niceNumber
} from '../../Utils/number';

import LoaderGraph from './loaderGraphQl';

import Typography from '../Atoms/typography';
import ErrorModal from '../Molecules/Modals/errorModal';

export default class KpiNumber extends Component {
  state = {
    count: 0,
    error: null,
    loading: true
  };

  componentDidMount() {
    this.init(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.init(nextProps);
  }

  init(props) {
    const {
      REACT_APP_PRESENTER_API
    } = process.env;
    const {
      countDomain
    } = this.props;

    const jsonWebToken = localStorage.getItem('jsonWebToken');

    if (jsonWebToken) {
      superagent.get(`${REACT_APP_PRESENTER_API}/${countDomain}`)
      .set('Authorization', `Bearer ${jsonWebToken}`)
      .end((err, res) => { 
        if(err) {
          this.setState({
            error: err.message,
            loading: false
          });

          return;
        }

        this.setState({
          count: res.body,
        }, () => this.setState({ loading: false }));
      });
    }
  }

  render () {
    let {
      query,
      title
    } = this.props;
    const {
      count,
      error,
      loading
    } = this.state;

    if (loading) return <LoaderGraph />;
    if (error) return <ErrorModal errorMessage={`Error: 😒 ${error}`}/>;

    return (
      <div>
        <Typography
          component='h2'
          textAlign='center'
        >
          { niceNumber(count, 0) }
        </Typography>
        <Typography
          component='caption'
          textAlign='center'
        >
          {title}
        </Typography>
      </div>
    );
  }
};
