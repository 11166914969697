import React from 'react';
import { withRouter } from 'react-router-dom';

import {
  capitalizeAll
} from '../../Utils/string';

import '../../Assets/Stylesheets/_card.scss';

import Hint from '../Atoms/hint';
import Icon from '../Atoms/icon';
import Typography from '../Atoms/typography';

class Card extends React.Component {

  render() {
    const {
      url,
      icon,
      title,
      status,
      children,
      description,
    } = this.props;
    let returnIcon = '';

    if (icon) {
      returnIcon = <Icon
        icon={icon}
        style={{
          width: '100px',
          height: '100px',
          fontSize: 90
        }}
      />;
    }
  
    return (
      <div
        className='card'
        onClick={url ? () => {
          this.props.history.push(url)
        } : null}
      >

        <div
          className='cardHeader'
        >

          {returnIcon ? <div
            className='cardIcon'
          >
            {returnIcon}
          </div> : null}

          {title ? <div
            className='cardTitle'
          >
            <Typography
              textAlign='center'
              component='h2'
            >
              {title}
            </Typography>
          </div> : null}

          {description ? <div
            className='cardDescription'
          >
            <Typography
              textAlign='center'
              component='caption'
            >
              {description}
            </Typography>
          </div> : null}
        </div>

        {children ? <div
          className='cardChildren'
        >
          {children}
        </div> : null}
        
        {status ? <div
          className='cardStatus'
        >
          <Typography
            textAlign='center'
            component='caption'
            >
            <Hint
              type={status === 'live' ? 'success' : status === 'coming_soon' ? 'warning' : 'info'}
              text={`Status: ${capitalizeAll(status)}`}
            />
          </Typography>
        </div> : null}
      
      </div>
    );
  }
}

export default withRouter(Card);