import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import '../../Assets/Stylesheets/_userAccountInteractions.scss';

import Typography from '../Atoms/typography';

class UserAccountInteractions extends Component {
  state = {
    user: false
  };

  componentDidMount() {
    this.init(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.init(nextProps);
  }

  init(props) {
    let user = localStorage.getItem('user');

    if (user) {
      this.setState({
        user: JSON.parse(user)
      });
    }
  }

  render() {
    const {
      user
    } = this.state;

    return (
      <div
      className={`userAccount ${user ? 'logout' : 'login'}`}
      onClick={() => {
        this.props.history.push({ search: '?user_actions=true' })
      }}>
        <Typography>
          { user ?
            'Logout' :
            'Login'
          }
        </Typography>
      </div>
    );
  }
}

export default withRouter(UserAccountInteractions);
