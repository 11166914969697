import React, { Component } from 'react';
import Grid from '../../Atoms/grid';

import Typography from '../../Atoms/typography';
import Card from '../../Molecules/card';
import DefaultTemplate from '../../Templates/defaultTemplate';

export default class ComponentLibrary extends Component {
  state = {
    selectedPage: false
  };

  render() {
    return (
      <DefaultTemplate>
        <Grid container>

          <Typography component='h1' textAlign='center'>
              Component Library
          </Typography>
        </Grid>
        <Grid
        columns={2}
        spacing={10}
        >
          <Card
            title='The Typography Component'
          >
             <Grid container>
              <Card
                title='Usage'
              >
                <Typography component='pre'>
                  {`import Typography from '../../Atoms/typography';`}
                </Typography>
                <Typography component='pre'>
                  {
        `<Typography component='h1' textAlign='center'>
  H1 Component
</Typography>`
                  }
                </Typography>
              </Card>
              <Card
                title='Examples'
              >
                <Grid container>
                  <Typography component='h1' textAlign='center'>
                    H1 Component
                  </Typography>
                  <Typography component='h2' textAlign='center'>
                    H2 Component
                  </Typography>
                  <Typography component='h3' textAlign='center'>
                    H3 Component
                  </Typography>
                  <Typography component='h4' textAlign='center'>
                    H4 Component
                  </Typography>
                  <Typography component='h5' textAlign='center'>
                    H5 Component
                  </Typography>
                  <Typography component='h6' textAlign='center'>
                    H6 Component
                  </Typography>
                  <Typography component='label' textAlign='center'>
                    I'm a label
                  </Typography>
                  <Typography component='caption' textAlign='center'>
                    I'm a caption
                  </Typography>
                  <Typography component='technical' textAlign='center'>
                    I'm a technical
                  </Typography>
                  <Typography component='pre' textAlign='center'>
                    I'm a pre
                  </Typography>
                </Grid>
              </Card>
            </Grid>
          </Card>
        </Grid>
        <Grid
        columns={2}
        spacing={10}
        >
          <Card
            title='The Card Component'
          >
            <Grid container>
              <Card
                title='Usage'
              >
                <Typography component='pre'>
                  {`import Card from '../../Molecules/card';`}
                </Typography>
                <Typography component='pre'>
                {
`<Card
  title='Sample Card'
  description='this is a Sample Card with the status in_development'
  status='in_development'
/>`
                }
                </Typography>
              </Card>
              <Card
                title='Examples'
              >
                <Grid columns={12} spacing={10}>
                  <Card
                    title='Sample Card'
                    description='this is a Sample Card with the status in_development'
                    status='in_development'
                  />
                </Grid>
                <Grid columns={12} spacing={10}>
                  <Card
                    title='Sample Card'
                    description='this is a Sample Card with the status live'
                    status='live'
                  />
                </Grid>
                <Grid columns={12} spacing={10}>
                  <Card
                    title='Sample Card'
                    description='this is a Sample Card with the status coming_soon'
                    status='coming_soon'
                  />
                </Grid>
                <Grid columns={12} spacing={10}>
                  <Card
                    title='Sample Card'
                    description='this is a Sample Card without status'
                  />
                </Grid>
                <Grid columns={12} spacing={10}>
                  <Card
                    title='Sample Card'
                    description='this is a Sample Card with children'
                  >
                    Hello World
                  </Card>
                </Grid>
                <Grid clear/>
              </Card>
            </Grid>
          </Card>
        </Grid>       
      </DefaultTemplate>
    );
  }
}
