import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Icon from '../Atoms/icon';

class HomeButton extends Component {
  render () {
    return (
      <div style={{
        width: '50px',
        margin: '10px auto'
      }}
      onClick={() => {
        this.props.history.push('/')
      }}>
        <Icon icon='home'/>
      </div>
    );
  }
};

export default withRouter(HomeButton);
