import React, { Component, Fragment } from 'react';

import Grid from '../Atoms/grid';
import Typography from '../Atoms/typography';
import FilterSelect from '../Atoms/filterSelect';

import {
  capitalize,
  capitalizeAll
} from '../../Utils/string';

class Filter extends Component {
  filterComponent(domain, table, keyName, config) {
    return (
      <FilterSelect
      pathname={`${domain}-dp-infos-dp-${table}`}
      keyName={keyName}
      />
    );
  }

  render() {
    const {
      model,
      domain
    } = this.props;
    const returnArray = [];

    if (model.attributes) {
      const {
        attributes
      } = model;

      if (attributes.infos) {
        const {
          infos
        } = attributes;

        Object.keys(infos).forEach(key => {
          const filterComponents = [];

          Object.keys(infos[key]).forEach(key2 => {
            if (
              infos[key][key2].actions
              && infos[key][key2].actions.includes('filter')
            ) {
              filterComponents.push(
                this.filterComponent(domain, key, key2, infos[key][key2])
              );
            }
          });

          returnArray.push(
            <Grid spacing={10}>
              <Typography
                key={key}
                component='h3'
                textAlign='center'
              >
                {capitalize(key)}
              </Typography>
              {filterComponents}
            </Grid>
          );
        })
      }
    }

    return returnArray;
  }
}

export default Filter;
