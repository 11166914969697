import { Component, Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';

import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';

import {
  capitalize,
  beautifyColumnName
} from '../../../../Utils/string';

import TableTypeIcon from '../../../Atoms/Icons/tableTypeIcon';
import ColumnActionIcon from '../../../Atoms/Icons/columnActionIcon';

const handleClick = () => {
  console.info('You clicked the Chip.');
};

const handleDelete = () => {
  console.info('You clicked the Chip.');
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

function Tooltipster(props) {
  const actions = [];

  if (props.actions) {
    props.actions.forEach(action => {
      actions.push(

        <Chip
          icon={<ColumnActionIcon action={action} />}
          label={action}
          variant="outlined"
          color="primary"
          deleteIcon={<AddCircleOutlineOutlinedIcon />}
          onClick={handleClick}
          onDelete={handleDelete}
        />
      );
    });
  }

  return <HtmlTooltip
    title={
      <Fragment>
        <Typography variant="h6" gutterBottom>
          {`${beautifyColumnName(props.title)}`}
        </Typography>
        <Typography variant="caption" gutterBottom>
          {capitalize(props.description)}
        </Typography>
        <Typography color="inherit">
          {actions}

        </Typography>
      </Fragment>
    }
    placement={props.alignment}
    interactive
  >
    {props.children}
  </HtmlTooltip>;
}

export default class ColumnActionsButton extends Component {
  render() {
    const {
      group,
      position,
      data,
      title,
      alignment
    } = this.props;

    return (
      <Tooltipster
        key={`avatar_${title}`}
        title={`${capitalize(title)}`}
        alignment={alignment}
        description={data.infos.description}
        actions={data.infos.actions}
        group={group}
      >
        <Avatar
          style={{
            ...{
              backgroundColor: 'pink',
              color: '#f50057',
              position: 'absolute',
              boxShadow: 'rgba(0, 0, 0, 0.05) 48px 53px 15px',
              top: `${position.top}`,
              left: `${position.left}`,
              width: `${position.width}`,
              height: `${position.height}`,
              zIndex: 2,
              marginBottom: `${30}px`
            }
          }}
        >
          <TableTypeIcon
            tableType={group}
            key={`link_circle_element_tabletype_icon_${title}`}
            style={{
              fontSize: '20px'
            }}
          />
        </Avatar>
      </Tooltipster>
    );
  }
}