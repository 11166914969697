import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import HomeWorkIcon from '@material-ui/icons/HomeWork';

import AlignedTopGoBackCenterIcon from '../Atoms/Icons/alignedTopGoBackCenterIcon';

export default function TopCentricDot(props) {
  const {
    domain,
    backPath
  } = props;

  return <AlignedTopGoBackCenterIcon>
    <Avatar
      onClick={() => window.location.href=`/${backPath}`}
      style={{
        background: '#f50057',
        color: 'white',
        display: 'inline-flex'
      }}
      key={`home_dot_${domain}`}
    >
      <HomeWorkIcon />
    </Avatar>
  </AlignedTopGoBackCenterIcon>;
}