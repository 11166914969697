import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { get } from 'superagent';
import Loader from '../Atoms/loader';
import Avatar from '@material-ui/core/Avatar';
import { withRouter } from 'react-router-dom';

import Icon from '../Atoms/icon';

// import Breadcrumbs from '@material-ui/core/Breadcrumbs';
// import WhatshotIcon from '@material-ui/icons/Whatshot';
// import Slide from '@material-ui/core/Slide';
// import HomeIcon from '@material-ui/icons/Home';
// import AppBar from '@material-ui/core/AppBar';
// import Link from '@material-ui/core/Link';

class TopDotNavigation extends Component {
  state = {
    loading: true,
    models: {},
    componentWidth: 0,
    componentHeight: 0,
    linePositionsX: []
  };

  componentDidMount() {
    this.init(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.init(nextProps);
  }

  init(props) {
    const {
      currentDomain
    } = props;
    const {
      REACT_APP_MODEL_API
    } = process.env;

    get(`${REACT_APP_MODEL_API}/explore`).end((err, res) => {
      if (err) {

        return;
      }
      const linePositionsX = [];

      Object.keys(res.body.apis).forEach(model => {
        if (model !== currentDomain) {
          const div = this[`location_div_${model}`];

          if (div) {
            linePositionsX.push(
              (div.offsetLeft + (div.clientWidth / 2))
            );
          }
        }
      });

      this.setState({
        models: res.body.apis,
        componentWidth: window.innerWidth,
        componentHeight: window.innerHeight,
        loading: false,
        linePositionsX: linePositionsX
      });


    });
  }

  render() {
    const {
      loading,
      models,
      componentWidth,
      linePositionsX
    } = this.state;
    let {
      currentDomain,
      currentGroup,
      location,
      pageType
    } = this.props;

    const returnDots = [];
    const connectorLines = [];
    const numberOfDomains = Object.keys(models).length;
    const stepper = (componentWidth / numberOfDomains);

    if (loading) {
      return <Loader />;
    }

    let type = 'explore';
    if(pageType) {
      type = pageType;
    }

    let group = '';
    if(currentGroup) {
      group = `/${currentGroup}`;
    }

    let params = '';
    if (location && location.search) {
      params = location.search;
    }

    let currentStep = 60;

    Object.keys(models).forEach(model => {
      if (model !== currentDomain) {
        returnDots.push(
          <Grid
          item
          xs={12}
          sm={1}
          style={{
            textAlign: 'center'
          }}
          key={`home_dot_${model}`}
          ref={(div) => {
            this[`location_div_${model}`] = div
          }}
          >
            <Avatar
            onClick={
              () => this.props.history.push(`/${type}/${model}${group}${params}`)
            }
            style={{
              background: 'white',
              display: 'inline-flex',
              color: 'black',
              backgroundColor: 'rgba(255, 255, 255, 0.50)',
              boxShadow: 'rgb(0 0 0 / 70%) 1px 1px 6px',
              position: 'relative',
              zIndex: 99999
            }}
            id={`home_dot_${model}`}
            >
              <Icon icon={model}/>
            </Avatar>
          </Grid>
        );
      }

      currentStep = currentStep + stepper;
    });

    if(linePositionsX) {
      linePositionsX.forEach(linePositionX => {
        connectorLines.push(<line
          key={`line_${Math.random()}`}
          x1={linePositionX}
          y1={52}
          x2={(componentWidth / 2) + 10}
          y2={105}
          stroke="rgba(255,255,255, 0.20)"
        />);
      });
    }

    return <Grid
    container
    direction="row"
    justify="space-between"
    alignItems="center"
    spacing={3}
    style={{
      position: 'fixed',
      top: '22px',
      left: '0',
      zIndex: 99999
    }}
    >
      <Grid
      item
      xs={12}
      sm={12}
      >
        <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        spacing={3}
        >
          {returnDots}
        </Grid>
      </Grid>
    </Grid>;
  }
}

export default withRouter(TopDotNavigation);