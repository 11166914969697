import React, { Component } from 'react';

import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { capitalize } from '../../Utils/string';

import Paper from '../Atoms/paper';

export default class ChartCard extends Component {
  state = {
    showSettings: false
  };

  toggleSettings() {
    this.setState({
      showSettings: !this.state.showSettings
    });
  }

  render() {
    const {
      style,
      title,
      description,
      children
    } = this.props;
    const {
      showSettings
    } = this.state;

    return <Paper
      style = {style}
    >
      <CardHeader
        title = {
          <Typography
          variant='subtitle1'
          display='block'
          color='textPrimary'
          noWrap
          align='left'
          gutterBottom
          >
            {capitalize(title)}
          </Typography>
        }
        subheader = {
          <Typography
          variant='caption'
          display='block'
          noWrap
          color='textSecondary'
          align='left'
          gutterBottom
          >
            {description}
          </Typography>
        }
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.6)'
        }}
        disableTypography
        action={
          <IconButton
          aria-label="settings"
          onClick={() => this.toggleSettings()}
          >
            <MoreVertIcon/>
          </IconButton>
        }
      />
      <CardContent
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.8)'
        }}
      >
        {showSettings ? 'Settings coming soon' : children}
      </CardContent>
    </Paper>;
  }
};
