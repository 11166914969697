import React from 'react';

import '../../Assets/Stylesheets/_grid.scss';

export default function Grid(props) {
  const classNames = [
    'grid'
  ];

  if (props.wrapper) {
    classNames.push('wrapper');
  }

  if (props.container) {
    classNames.push('container');
  }

  if (props.item) {
    classNames.push('item');
  }

  if (props.clear) {
    classNames.push('clear');
  }

  if (props.spacing) {
    classNames.push(`spacing-${props.spacing}`);
  }

  if (props.columns) {
    classNames.push(`columns-${props.columns}`);
  }

  return <div className={`${classNames.join(' ')}`} >
    {props.children}
  </div>;
}