import '../../Assets/Stylesheets/_filterModal.scss';

import React, { Component, Fragment } from 'react';
import { get } from 'superagent';

import {
  capitalize
} from '../../Utils/string';

import Grid from '../Atoms/grid';
import Icon from '../Atoms/icon';
import Loader from '../Atoms/loader';
import Typography from '../Atoms/typography';
import Filter from '../Molecules/filter';
import ErrorModal from '../Molecules/Modals/errorModal';

export default class FilterModal extends Component {
  state = {
    model: {},
    error: false,
    loading: true,
    modalShown: false,
    componentWidth: 0,
    componentHeight: 0
  }

  componentDidMount() {
    this.init(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.init(nextProps);
  }

  init(props) {
    const {
      REACT_APP_MODEL_API
    } = process.env;

    get(`${REACT_APP_MODEL_API}/explore/${props.domain}`).end((err, res) => {
      if (err) {
        this.setState({
          error: 'is your data model running?'
        });

        return;
      }

      this.setState({
        model: res.body[props.domain],
        componentWidth: window.innerWidth,
        componentHeight: window.innerHeight,
      }, () => this.setState({
        loading: false
      }));
    });
  }

  switchModal() {
    const {
      modalShown
    } = this.state;

    this.setState({
      modalShown: !modalShown
    });
  }

  render() {
    const {
      modalShown,
      model,
      loading,
      error
    } = this.state;
    const {
      domain
    } = this.props;

    let bottomPosition = modalShown === false ? 'calc(-100% + 200px)' : '0';
    let topPosition = modalShown === false ? '-100%' : '0';
    let icon = modalShown === false ? 'plus' : 'minus';

    return (
      <Fragment>
        <div
        className='filterModalTop'
        style={{top: topPosition}}
        />
        <div
        className='filterModal'
        style={{bottom: bottomPosition}}
        >
          <Grid spacing={10}>
            <Grid spacing={10}>
              <div
              className='button'
              onClick={() => this.switchModal()}
              >
                <Icon icon={icon}/>
              </div>
            </Grid>
            <Grid spacing={10}>
              <Typography
              component='h1'
              textAlign='center'
              >
                {capitalize(domain)} Filter
              </Typography>
            </Grid>
            <Grid spacing={10}>
              <div className='filterContainer'>
                {error ? <ErrorModal errorMessage={`Error: 😒 ${error}`}/> : null}
                {loading ? <Loader/> : null}
                {Object.keys(model).length > 0 ? <Filter model={model} domain={domain}/> : null}
              </div>
            </Grid>
          </Grid>
        </div>
      </Fragment>
    );
  }
}
