import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '../../Atoms/grid';
import AppHeading from '../../Atoms/appHeading';
import HomePageEye from '../../Atoms/homePageEye';
import DataTable from '../../Molecules/dataTable';
import DevicePlugins from '../../Organisms/devicePlugins';
import DefaultTemplate from '../../Templates/defaultTemplate';
import Card from '../../Molecules/card';

// TODO: Do not scan networks here. 
// Build a service for pushing current device inventory to the data api
class HomeNetworkPage extends Component {
  render() {
    const dataTypeMapping = {
      last_seen: 'dateTime',
      count_seen: 'integer',
      first_seen: 'dateTime',
      device_names: 'device',
      device_vendors: 'vendor',
      device_internal_ips: 'ip',
      device_mac_adresses: 'identifier',
    };

    return (
      <DefaultTemplate title='Home Network'>
        <AppHeading text={`Home Network`} />
        {/* <Grid container>
          <HomePageEye />
        </Grid> */}
        <Grid
        container
        >
          <Grid columns={12} spacing={10}>
            <Card
              title='Philips Hue'
              description='Connect with your local Philips Hue Bridge and control your Bulbs from this Platform'
            >
              <DevicePlugins/>
            </Card>
          </Grid>
          <Grid columns={12} spacing={10}>
            <Card
              title='Network Devices'
              description='This is a list of all the devices found during network scanning. This view is aggregated by mac adresses'
            >
              <DataTable
                pluginColumn={{ mac_adress_column: 'device_mac_adresses' }}
                dataTypeMapping={dataTypeMapping}
                url={'views-network/mac-adresses'}
              />
            </Card>
          </Grid>
        </Grid>
      </DefaultTemplate>
    );
  }
}

export default withRouter(HomeNetworkPage);
