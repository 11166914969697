import React, { Component } from 'react';
import { withRouter } from 'react-router';
import superagent from 'superagent';

import {
  setQueryParam,
  getQueryParams
} from '../../Utils/query';

import '../../Assets/Stylesheets/_table.scss';

import { capitalizeAll } from '../../Utils/string';

import Icon from '../Atoms/icon';
import AddDevicePlugin from '../Atoms/addDevicePlugin';
import Loader from '../Atoms/loader';
import Typography from '../Atoms/typography';
import Pagination from '../Molecules/pagination';
import ErrorModal from '../Molecules/Modals/errorModal';

class DataTable extends Component {
  constructor(props) {
    super(props);

    this.setQueryParam = setQueryParam.bind(this);
    this.getQueryParams = getQueryParams.bind(this);
  }

  state = {
    data: null,
    componentWidth: 0,
    loading: true,
    error: false,
    sortDirection: 'asc'
  };

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.setState({
        loading: true,
        data: null
      }, () => this.loadData());
    });

    this.loadData();
  }

  componentWillUnmount() {
    this.unlisten();
  }

  setSortParam(keyName) {
    const queryParams = this.getQueryParams();
    const sortParams = queryParams._sort;
    let sortDirection = 'desc';

    if (sortParams) {
      let currentSortDirection = sortParams.split(':');
      sortDirection = currentSortDirection[1] === 'asc' ? 'desc' : 'asc';
    }

    this.setQueryParam({ _sort: `${keyName}:${sortDirection}` })  
  }

  loadData() {
    const {
      REACT_APP_PRESENTER_API
    } = process.env;
    const {
      url
    } = this.props;

    let jsonWebToken = localStorage.getItem('jsonWebToken');

    if (!jsonWebToken) {
      this.setState({
        loading: false,
        error: 'You are not logged in....'
      });
    }

    if (jsonWebToken) {
      superagent.get(`${REACT_APP_PRESENTER_API}/${url}${this.props.history.location.search}`)
      .set('Authorization', `Bearer ${jsonWebToken}`)
      .end((err, res) => {
        if (err) {
          this.setState({
            error: err.message,
            loading: false
          });

          return;
        }

        this.setState({
          data: res.body,
          loading: false,
          error: false
        });
      });
    }
  }

  formatDate(value) {
    var date = new Date(value).toLocaleDateString('de-De', {timeZone: 'Europe/Berlin'});
    var time = new Date(value).toLocaleTimeString('de-De', {timeZone: 'Europe/Berlin'});

    return (
      <div className='dateTimeDiv'>
        <div className='dataTableDiv dateDiv'>
          <Icon style={{ width: '30px', float: 'left' }} icon='date'/>
          <span style={{ float: 'left' }}>
            {date}
          </span>
         <div className='clear'/>
        </div>
        <div className='dataTableDiv timeDiv'>
          <Icon style={{ width: '30px', float: 'left' }} icon='time'/>
          <span style={{ float: 'left' }}>
            {time}
          </span>
          <div className='clear'/>
        </div>
      </div>
    );
  }

  formatHeaderKeys(string) {
    return capitalizeAll(string);
  }

  tableKeys(data) {
    const elements = [];

    Object.keys(data).forEach(key => {
      Object.keys(data[key]).forEach(key2 => {
        if (!elements.includes(key2)) {
          elements.push(key2);
        }
      });
    });

    return elements;
  }

  formatData(value, dataTypeMapping) {
    if (!dataTypeMapping || !value) {
      return value;
    }

    if (dataTypeMapping === 'dateTime') {
      return this.formatDate(value);
    }

    if (dataTypeMapping) {
      return <div className={`dataTableDiv ${dataTypeMapping}Div`}>
        <div style={{ width: '30px', float: 'left' }} >
          <Icon icon={dataTypeMapping}/>
        </div>
        <span style={{ float: 'left' }}>
          {value}
        </span>
        <div className='clear'/>
      </div>;
    }

    return value;
  }

  table(data) {
    const tableHead = this.tableKeys(data);
    const {
      dataTypeMapping,
      pluginColumn
    } = this.props;

    return (
      <table className='dataTable'>
        <thead>
          <tr>
            {pluginColumn ? <td><Typography>Plug-In</Typography></td> : null}
            {tableHead.map((name) => (
              <td
              key={`tableHeadCell${name}`}
              onClick={() => this.setSortParam(name)}
              >
                <Typography>
                  {this.formatHeaderKeys(name)}
                </Typography>
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={Math.random()}>
              {pluginColumn ? <td data-label='Add Plugin'><AddDevicePlugin macAdress={row[pluginColumn.mac_adress_column]}/></td> : null}
              {tableHead.map((name) => (
                <td
                data-label={this.formatHeaderKeys(name)}
                key={`tableBodyCell${name}`}
                >
                  <Typography>
                    {this.formatData(
                      row[name],
                      dataTypeMapping && dataTypeMapping[name] ? dataTypeMapping[name] : null 
                    )}
                  </Typography>
                  <div className='clear'/>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  render() {
    const {
      error,
      data
    } = this.state;
    const {
      url
    } = this.props;

    let returnDiv = [];

    if (error) {
      returnDiv.push(
        <ErrorModal
          errorMessage={`😒 ${error}`}
        />
      );
    }

    if (!data && !error) {
      returnDiv.push(
        <Loader />
      );
    }

    if (data && Object.keys(data).length === 0) {
      returnDiv.push(
        'Nothing to show'
      );
    }

    if (data && Object.keys(data).length > 0) {
      const pagination = <Pagination url={url}/>;

      returnDiv.push(pagination);
      
      returnDiv.push(
        this.table(data)
      );

      returnDiv.push(pagination);
    }

    return returnDiv;
  }
}

export default withRouter(DataTable);