import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Icon from '../Atoms/icon';

class BackButton extends Component {
  render () {
    const {pathname} = this.props.location;

    return (
      <div style={{
        width: '50px',
        left: '50%',
        marginLeft: '-25px',
        marginTop: '-55px',
        position: 'fixed',
        display: `${pathname === '/' ? 'none' : 'block'}`
      }}
      onClick={() => {
        this.props.history.goBack()
      }}>
        <Icon icon='back'/>
      </div>
    );
  }
};

export default withRouter(BackButton);