import React from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '../../Atoms/grid';
import KpiNumber from '../../Atoms/kpiNumber';
import Card from '../../Molecules/card';
import ChartCard from '../../Molecules/card';
import DataTable from '../../Molecules/dataTable';
import TimeSeriesChart from '../../Molecules/Charts/timeSeriesChart';

function WebDashboard() {
  const rootstyle = {
    // padding: '90px 20px 20px 20px',
    // position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    boxSizing: 'border-box',
  }

  const dataTypeMapping = {
    id: 'integer',
    url_domains: 'info',
    url_pathes: 'info',
    url_parameters: 'info',
    browser_names: 'info',
    browser_versions: 'info',
    connection_speeds: 'info',
    user_checked_ins: 'info',
    user_tokens: 'identifier',
    posted_ats: 'dateTime',
  };

  return (
    <div style={rootstyle}>
      <Grid
        container
      >
        <Grid
          columns={6}
          spacing={10}
        >
          <ChartCard
          title='Visits last 60 mins'
          description='shows the visits per domain in the last 60 mins'
          >
            <TimeSeriesChart
            dataUrl = '/views-web/views-last-hour'
            values = 'views'
            separateBy='url_domains'
            showBy='hour_minute'
            type='bar'
            />
          </ChartCard>
        </Grid>
        <Grid columns={2} spacing={10}>
          <Card
            title='Domains'
            description='number of domains tracked ever'
          >
            <KpiNumber
              countDomain='web-dp-links-dp-url-domains/count'
              title='domains'
            />
          </Card>
        </Grid>
        <Grid columns={2} spacing={10}>
          <Card
            title='Visits'
            description='total visits on tracked domains'
          >
            <KpiNumber
              countDomain='web-dp-metrics-dp-totals/count'
              title='visits total'
            />
          </Card>
        </Grid>
        <Grid columns={2} spacing={10}>
          <Card
            title='Operating Systems'
            description='total operating systems counted'
          >
            <KpiNumber
              countDomain='web-dp-links-dp-operatingsystem-platforms/count'
              title='operating systems'
            />
          </Card>
        </Grid>
        <Grid columns={2} spacing={10}>
          <Card
            title='Browsers'
            description='total browsers counted'
          >
            <KpiNumber
              countDomain='web-dp-links-dp-browser-names/count'
              title='browsers'
            />
          </Card>
        </Grid>
        <Grid
          columns={12}
          spacing={10}
        >
          <Card
            title='Last 100 Visits'
          >
            <DataTable
              dataTypeMapping={dataTypeMapping}
              url={'views-web/all'}
            />
          </Card>
        </Grid>
      </Grid>
      <Grid clear/>
      
    </div>
  );
}

export default withRouter(
  WebDashboard
);
