import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import AppHeading from '../../Atoms/appHeading';
import BreadCrumbBar from '../../Molecules/breadCrumbBar';
import TopCentricDot from '../../Molecules/topCentricDot';
import TopDotNavigation from '../../Molecules/topDotNavigation';
import ExploreDomain from '../../Organisms/exploreDomain';
import DefaultTemplate from '../../Templates/defaultTemplate';

class DatamodelDomainExplorer extends Component {
  render() {
    const {
      match
    } = this.props;
    const {
      params
    } = match;

    return (
      <DefaultTemplate
      blurred
      >
        <TopDotNavigation
        currentDomain={params.domain}
        />
        <AppHeading
        text={`explore ${params.domain}`}
        />
        <BreadCrumbBar
        pageName='Explore'
        domain={params.domain}
        url='explore'
        />
        <TopCentricDot domain={params.domain} backPath='explore'/>

        <ExploreDomain
        domain={params.domain}
        />
      </DefaultTemplate>
    );
  }
}

export default withRouter(DatamodelDomainExplorer);
