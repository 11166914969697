function average(arrayOfNumbers) {
  if (arrayOfNumbers === 0) {
    return 0;
  }

  return (sum(arrayOfNumbers) / count(arrayOfNumbers)) || 0;
}

function count(arrayOfNumbers) {
  return arrayOfNumbers.length;
}

function sum(arrayOfNumbers) {
  return arrayOfNumbers.reduce((a, b) => a + b, 0);
}

function min(arrayOfNumbers) {
  return Math.min(...arrayOfNumbers);
}

function max(arrayOfNumbers) {
  return Math.max(...arrayOfNumbers);
}

function parseAsNumber(string) {
  return parseFloat(string);
}

export {
  parseAsNumber,
  average,
  count,
  sum,
  min,
  max
}