import React, { Component } from 'react';
import superagent from 'superagent';

import Typography from '../Atoms/typography';

import '../../Assets/Stylesheets/_hue.scss';

export default class PluginCard extends Component {
  state = {
    hueToken: null,
    data: {},
    hueIp: null,
    waitingForBridgeLink: true,
    intervalCount: 0,
    lights: [],
    intervalId: null
  }

  componentDidMount() {
    const {
      data,
      ipAdress
    } = this.props;
    let hueToken = localStorage.getItem('hueToken');

    if (hueToken && data && ipAdress) {
      this.setState({
        hueToken,
        hueIp: ipAdress,
        data
      });
    }
  }

  requestToken() {
    const {
      intervalId
    } = this.state;
    const {
      ipAdress
    } = this.props;

    if (ipAdress) {
      superagent.post(`http://${ipAdress}/api`)
      .send({
        devicetype: 'HomeDataPlatform'
      })
      .end((err, res) => {
        if (err) {
          this.setState({
            error: res.body[0].error.description
          });

          return;
        }

        if (res.body[0] && res.body[0].error) {
          this.setState({
            error: res.body[0].error.description
          });
        }

        if (res.body[0] && res.body[0].success) {
          localStorage.setItem('hueToken', res.body[0].success.username);

          this.setState({
            waitingForBridgeLink: false,
            hueToken: res.body[0].success.username,
            error: false
          }, () => {
            clearInterval(intervalId);
          });
        }
      });
    }
  }

  requestTokenHandler() {
    const {
      ipAdress
    } = this.props;

    if (ipAdress) {
      this.setState({
        waitingForBridgeLink: true,
      }, () => {
        const intervalId = setInterval(() => this.requestToken(), 1000);

        setTimeout(() => { clearInterval(intervalId) }, 20000);

        this.setState({
          intervalId
        });
      });
    }
  }

  requestAllLights() {
    const {
      hueToken,
      lights
    } = this.state;
    const {
      ipAdress
    } = this.props;

    if (ipAdress && lights.length === 0) {
      superagent.get(`http://${ipAdress}/api/${hueToken}/lights`)
      .end((err, res) => {
        if (err) {
          this.setState({
            error: err
          });

          return;
        }

        this.setState({
          lights: res.body
        });
      });
    }
  }

  toggleLightState(id, light) {
    const {
      hueToken,
      lights,
      hueIp
    } = this.state;
    const lightState = !light.state.on;

    superagent.put(`http://${hueIp}/api/${hueToken}/lights/${id}/state`)
    .send({
      on: lightState
    })
    .end((err, res) => {
      if(res.body[0].success) {
        light.state.on = res.body[0].success[`/lights/${id}/state/on`];
        
        this.setState({
          lights: {
            ...lights,
            ...{
              [id]: light
            }
          }
        });
      }
    });
  }

  getAllLights() {
    const {
      lights
    } = this.state;
    const returnArray = [];

    this.requestAllLights();

    Object.keys(lights).forEach(light => {
      returnArray.push(
        <div className='light' key={`hueLight${light}`}>
          <Typography
            component='h3'
            textAlign='center'
          >
            {lights[light].name}
          </Typography>
          {this.getLight(light, lights[light])}
        </div>
      );
    })

    return returnArray;
  }

  xyBriToRgb(x, y, bri) {
    let z = 1.0 - x - y;

    let Y = bri / 255.0; // Brightness of lamp
    let X = (Y / y) * x;
    let Z = (Y / y) * z;
    let r = X * 1.612 - Y * 0.203 - Z * 0.302;
    let g = -X * 0.509 + Y * 1.412 + Z * 0.066;
    let b = X * 0.026 - Y * 0.072 + Z * 0.962;
    r = r <= 0.0031308 ? 12.92 * r : (1.0 + 0.055) * Math.pow(r, (1.0 / 2.4)) - 0.055;
    g = g <= 0.0031308 ? 12.92 * g : (1.0 + 0.055) * Math.pow(g, (1.0 / 2.4)) - 0.055;
    b = b <= 0.0031308 ? 12.92 * b : (1.0 + 0.055) * Math.pow(b, (1.0 / 2.4)) - 0.055;
    let maxValue = Math.max(r, g, b);
    r /= maxValue;
    g /= maxValue;
    b /= maxValue;
    r = r * 255; if (r < 0) { r = 255 };
    g = g * 255; if (g < 0) { g = 255 };
    b = b * 255; if (b < 0) { b = 255 };

    r = Math.round(r).toString(16);
    g = Math.round(g).toString(16);
    b = Math.round(b).toString(16);

    if (r.length < 2)
      r = "0" + r;
    if (g.length < 2)
      g = "0" + g;
    if (b.length < 2)
      b = "0" + r;
    const rgb = "#" + r + g + b;

    return rgb;
  }

  getColorLight(id, light) {
    const hueToRgb = this.xyBriToRgb(
      light.state.xy[0],
      light.state.xy[1],
      light.state.bri
    );
  
    return this.getBulb(
      id,
      light,
      {backgroundColor: hueToRgb, boxShadow: `0 0 50px ${hueToRgb}`}
    );
  }

  getBulb(id, light, style = {}) {
    if(!light.state.on) {
      style = {
        backgroundColor: '#dcd8d8',
        boxShadow: '0 0 0'
      }
    }

    return <div className='lightBulb'>
      <div className='base'>
        <div
        className='bulb'
        style={style}
        onClick={() => {
          this.toggleLightState(id, light)
        }}
        />
      </div>
    </div>;
  }

  getDimmableLight(id, light) {
    return this.getBulb(id, light, {});
  }

  getLight(id, light) {
    switch (light.type) {
      case 'Extended color light':
        return this.getColorLight(id, light);
      case 'Color light':
        return this.getColorLight(id, light);
      case 'Dimmable light':
        return this.getDimmableLight(id, light);
      default:
        return `Missing Light type ${light.type}`;
    }
  }

  conntectHue() {
    return <div
      className='connectHue'
      onClick={() => {
        this.requestTokenHandler();
      }}
    >
      <Typography>
        Connect Hue
      </Typography>
    </div>;
  }

  render() {
    const {
      data,
      hueToken,
      error
    } = this.state;

    if (error) {
      return error;
    }

    if (Object.keys(data).length === 0 && !hueToken) {
      return this.conntectHue();
    }

    return <div className='bulbContainer'>
      <Typography
      component='h2'
      textAlign='center'
      >
        Bulbs
      </Typography>
      <div className='bulbInnerContainer'>
        {this.getAllLights()}
        <div className='clear'/>
      </div>
    </div>;
  }
}
