
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import GridLayout from 'react-grid-layout';

import KpiNumber from '../../Atoms/kpiNumber';
import AppHeading from '../../Atoms/appHeading';
import ChartCard from '../../Molecules/chartCard';
import BreadCrumbBar from '../../Molecules/breadCrumbBar';
import DefaultTemplate from '../../Templates/defaultTemplate';

function MyResponsiveGrid(props) {
  const layout = [
    {i: 'a', x: 1, y: 0, w: 5, h: 2},
    {i: 'b', x: 6, y: 4, w: 3, h: 2, minW: 2, maxW: 4},
    {i: 'c', x: 1, y: 4, w: 5, h: 6},
    {i: 'd', x: 9, y: 1, w: 2, h: 4},
    {i: 'e', x: 6, y: 6, w: 5, h: 2},
    {i: 'f', x: 6, y: 4, w: 3, h: 2}
  ];

  return (
    <GridLayout
    className="layout"
    layout={layout}
    cols={12}
    rowHeight={30}
    width={props.width}
    useCSSTransforms = {false}
    resizeHandles = {['ne', 'nw', 'sw', 'se']}
    containerPadding = {0}
    >
      <div key="a">Visual. 1</div>
      <div key="b">Visual. 2</div>
      <div key="c">
        <ChartCard
          title='Registered Customers'
        >
          <KpiNumber
            query = {
              `query {
                customerXLinksXCustomerIdsConnection {
                  aggregate {totalCount}
                }
              }`}
            title= 'customer count'
          />
        </ChartCard>
      </div>
      <div key="d">Visual. 4</div>
      <div key="e">Visual. 5</div>
      <div key="f">Visual. 6</div>
    </GridLayout>
  );
}
class ReportsPage extends Component {
  state = {
    componentWidth: 0
  };

  componentDidMount() {
    const width = this.divElement.clientWidth;

    this.setState({
      componentWidth: width
    });
  }

  render() {
    const {
      componentWidth
    } = this.state;

    return (
      <DefaultTemplate
        title='data explorer'
        fixedLogo
      >
        <BreadCrumbBar pageName='Explore' url='explore' />
        <AppHeading text={`Sample Report`}/>
        <Grid
        ref={ (divElement) => { this.divElement = divElement } }
        container>
          <MyResponsiveGrid width={componentWidth}/>
        </Grid>
      </DefaultTemplate>
    );
  }
}

export default withRouter(ReportsPage);
