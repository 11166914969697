import React from 'react';

import Typography from './typography';

export default function AppHeading(props) {
  const {
    text,
  } = props;

  return (
    <Typography component='h1' textAlign='center'>
      {text.toUpperCase()}
    </Typography>
  );
};
