import React, { Component, Fragment } from 'react';
import { get } from 'superagent';
import { withRouter } from 'react-router-dom';

import Card from '../card';
import Grid from '../../Atoms/grid';
import ErrorModal from '../Modals/errorModal';

class DataModelCards extends Component {
  state = {
    domains: {},
    selectedModel: false,
    dataModelMap: false,
    loading: true
  };

  componentDidMount() {
    this.init();
  }

  init() {
    const {
      REACT_APP_MODEL_API
    } = process.env;

    get(`${REACT_APP_MODEL_API}/explore`).end((err, res) => {
      if (err) {
        this.setState({
          error: 'is your data model running?'
        });

        return;
      }

      this.setState({
        domains: res.body.apis,
        loading: false,
        selectedModel: false
      });
    });
  }

  loadDomains(bind) {
    bind.init();
  }

  setSelectedMap(bind, domain) {
    bind.setState({
      dataModelMap: domain,
      loading: true
    });
  }

  setSelectedModel(bind, domain) {
    bind.setState({
      selectedModel: domain,
      loading: true
    });
  }

  render() {
    const {
      error,
      loading,
      domains,
      selectedModel
    } = this.state;
    let cardsReturnArray = [];

    if(error) {
      return <ErrorModal
      errorMessage={`Error: 😒 ${error}`}
      />;
    }

    if (!loading) {
      Object.keys(domains).forEach(domain => {
        cardsReturnArray.push(
          <Grid
          columns={2} spacing={10}
          key={`domainCard${domain}`}
          >
            <Card
            title={domains[domain].config.domain}
            description={domains[domain].config.description}
            icon={domains[domain].config.domain}
            url={`/dashboard/${domains[domain].config.domain}`}
            status={domains[domain].config.status}
            />
          </Grid>
        );
      });
    }

    return cardsReturnArray;
  }
};

export default withRouter(
  DataModelCards
);
