import React, { Component } from 'react';
import superagent from 'superagent';

import { capitalize } from '../../Utils/string';

import Loader from '../Atoms/loader';
import Typography from '../Atoms/typography';
import Card from '../Molecules/card';
import ErrorModal from '../Molecules/Modals/errorModal';

const kanyeRestUrl = 'https://api.kanye.rest';

export default class KanyeRest extends Component {
  state = {
    showSettings: false,
    error: null,
    data: false
  };

  componentWillReceiveProps(nextProps) {
    this.loadData(nextProps.dataUrl);
  }

  componentDidMount() {
    this.loadData(this.props.dataUrl);
  }

  loadData(url) {
    superagent.get(kanyeRestUrl)
      .end((err, res) => {
        if (err) {
          this.setState({
            error: `Couldn't fetch ${kanyeRestUrl}`,
            loading: false
          });

          return;
        }

        this.setState({
          data: res.body.quote,
          loading: false
        });
      });
  }

  toggleSettings() {
    this.setState({
      showSettings: !this.state.showSettings
    });
  }

  render() {
    const {
      style,
    } = this.props;
    const {
      showSettings,
      data,
      error
    } = this.state;

    let returnDiv = <Loader/>;

    if (data) {
      returnDiv = <Typography
      component='technical'
      textAlign='center'
      >
        "{data}"
      </Typography>;
    }

    if (error) {
      returnDiv = <ErrorModal
      errorMessage={`😒 ${error}`}
      />;
    }

    return <Card
      title={capitalize(`Kanye Rest`)}
    >
      {returnDiv}
    </Card>;
  }
};
