import React, { Component, Fragment } from 'react';
import { get } from 'superagent';
import { withRouter } from 'react-router-dom';

import {
  setQueryParam,
  getQueryParams,
  deleteParam
} from '../../Utils/query';
import { asCircle } from '../../Utils/arrangeItems';

import '../../Assets/Stylesheets/_navigator.scss'

import Loader from '../Atoms/loader';
import Icon from '../Atoms/icon';
import DomainDot from './domainDot';
import ErrorModal from './Modals/errorModal';

class Navigator extends Component {
  constructor() {
    super();

    this.handleResize = this.handleResize.bind(this);
    this.deleteParam = deleteParam.bind(this);
    this.setQueryParam = setQueryParam.bind(this);
    this.getQueryParams = getQueryParams.bind(this);
  }

  state = {
    loading: true,
    error: false,
    model: {},
    componentWidth: window.innerWidth,
    componentHeight: window.innerHeight
  };

  componentDidMount() {
    this.init(this.props);

    window.addEventListener('resize', this.handleResize);

    this.unlisten = this.props.history.listen((location, action) => {
      this.init(this.props);
    });
  }

  handleResize() {
    this.setState({
      componentWidth: window.innerWidth,
      componentHeight: window.innerHeight,
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  componentWillReceiveProps(nextProps) {
    this.init(nextProps);
  }

  init(props) {
    const {
      domain,
      group,
    } = props;
    const {
      REACT_APP_MODEL_API
    } = process.env;

    get(`${REACT_APP_MODEL_API}/explore/`).end((err, res) => {
      if (err) {
        this.setState({
          error: 'is your data model running?'
        });

        return;
      }

      this.setState({
        model: res.body.apis,
      }, () =>
        this.setState({
          loading: false
        })
      );
    });
  }

  circle(models, radius) {
    let {
      componentWidth,
      componentHeight
    } = this.state;

    const outerElementsDiameter = 70;
    const innerElementDiameter = 40;

    const circlePositions = asCircle(
      models,
      100,
      outerElementsDiameter,
      innerElementDiameter,
      componentWidth,
      componentHeight,
      'center',
      400
    );

    const centricElement = <DomainDot
        top={circlePositions.centricElement.positionTop}
        left={circlePositions.centricElement.positionLeft}
        width={circlePositions.centricElement.elementWidth}
        height={circlePositions.centricElement.elementHeight}
        icon={<Icon width='20px' style={{width: '20px'}} icon='home' key={`centric_circle_DomainIcon_centric`} />}
        type='centric'
        key={`centric_circle_${Math.random()}`}
        className='centricDot'
        description={'Please select a Domain'}
        // style={{...{
        //   backgroundColor: '#f50057',
        //   boxShadow: '48px 53px 15px rgba(0, 0, 0, 0.05)',
        //   color: 'white'
        // }
      // }}
      />;

    const circleArray = [];
    const connectorLines = [];

    let incrementer = 0;
    
    circleArray.push(centricElement);

    Object.keys(models).forEach(model => {
      const {
        config
      } = models[model];
      const circleElementPosition = circlePositions.circleElements[incrementer];
      const lineElementPosition = circlePositions.lineElements[incrementer];

      let domainIcon = [];
      let description = '';
      let domain = '';
      let title = model;

      if (config) {
        description = config.description;
        domain = config.domain;
        title = domain;
      }

      domainIcon.push(
        <Icon style={{width: '30px'}} key={`${title}_domain_icon_${domain}_inner`} icon={domain} />
      );

      circleArray.push(
        <DomainDot
          key={`${title}_circle_${domain}`}
          top={circleElementPosition.positionTop}
          left={circleElementPosition.positionLeft}
          width={circleElementPosition.elementWidth}
          height={circleElementPosition.elementHeight}
          icon={domainIcon}
          description={description}
          title={title}
        />
      );

      connectorLines.push(<line
        key={`${title}_line_${domain}`}
        x1={lineElementPosition.positionLeftStartPoint}
        y1={lineElementPosition.positionTopStartPoint}
        x2={lineElementPosition.positionLeftEndPoint}
        y2={lineElementPosition.positionTopEndPoint}
        stroke="rgba(0, 0, 0, 0.1)"
      />);

      incrementer++;
    });

    return <Fragment>
      {circleArray}

      <svg style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
      }}
        // key={`svg_${Math.random()}`}
      >
        {connectorLines}
      </svg>
    </Fragment>;
  };

  render() {
    let {
      model,
      loading,
      error,
      componentWidth,
      componentHeight
    } = this.state;
    const queryParams = this.getQueryParams();

    if(error) {
      return <ErrorModal
      errorMessage={`Error: 😒 ${error}`}
      />;
    }

    if (loading) {
      return <Loader style={{marginTop: '200px'}}/>;
    }

    if(queryParams.navigator) {
      return <div className='navigator'>
        {this.circle(model, 150)}
      </div>;
    }
    

    return <div
    onClick={() => this.setQueryParam({navigator: true})}
    className='navigatorToggler'
    >
      <Icon icon='navigator'/>
    </div>
  }
}

export default withRouter(Navigator);
