import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { get } from 'superagent';
import Loader from '../Atoms/loader';
import { asCircle } from '../../Utils/arrangeItems';
import Icon from '../Atoms/icon';
import DomainDot from '../Molecules/domainDot';

class DatamodelMap extends Component {
  state = {
    componentWidth: 0,
    componentHeight: 0,
    models: {},
    loading: true
  };

  componentDidMount() {
    this.init();
  }

  init() {
    const {
      REACT_APP_MODEL_API
    } = process.env;

    get(`${REACT_APP_MODEL_API}/explore`).end((err, res) => {
      if (err) {

        return;
      }

      this.setState({
        models: res.body.apis,
        componentWidth: window.innerWidth,
        componentHeight: window.innerHeight,
        loading: false
      });
    });
  }

  circle(models, radius) {
    let {
      componentWidth,
      componentHeight
    } = this.state;

    const outerElementsDiameter = 100;
    const innerElementDiameter = 60;

    const circlePositions = asCircle(
      models,
      150,
      outerElementsDiameter,
      innerElementDiameter,
      componentWidth,
      componentHeight,
      'center',
      600
    );

    const centricElement = <DomainDot
      top={circlePositions.centricElement.positionTop}
      left={circlePositions.centricElement.positionLeft}
      width={circlePositions.centricElement.elementWidth}
      height={circlePositions.centricElement.elementHeight}
      icon={<Icon icon='home' key={`centric_circle_DomainIcon_centric`} />}
      type='centric'
      key={`centric_circle_${Math.random()}`}
      description={'Please select a Domain'}
      style={{
        backgroundColor: '#f50057',
        boxShadow: '48px 53px 15px rgba(0, 0, 0, 0.05)',
        color: 'white'
      }}
    />;

    const circleArray = [];
    const connectorLines = [];

    let incrementer = 0;

    
    circleArray.push(centricElement);


    Object.keys(models).forEach(model => {
      const {
        config
      } = models[model];
      const circleElementPosition = circlePositions.circleElements[incrementer];
      const lineElementPosition = circlePositions.lineElements[incrementer];

      let domainIcon = [];
      let description = '';
      let domain = '';
      let title = model;

      if (config) {
        description = config.description;
        domain = config.domain;
        title = domain;
      }

      domainIcon.push(<Icon key={`${title}_domain_icon_${domain}_inner`} icon={domain} />);

      circleArray.push(
        <DomainDot
          key={`${title}_circle_${domain}`}
          top={circleElementPosition.positionTop}
          left={circleElementPosition.positionLeft}
          width={circleElementPosition.elementWidth}
          height={circleElementPosition.elementHeight}
          icon={domainIcon}
          description={description}
          title={title}
        />
      );

      connectorLines.push(<line
        key={`${title}_line_${domain}`}
        x1={lineElementPosition.positionLeftStartPoint}
        y1={lineElementPosition.positionTopStartPoint}
        x2={lineElementPosition.positionLeftEndPoint}
        y2={lineElementPosition.positionTopEndPoint}
        stroke="rgba(0, 0, 0, 1)"
      />);

      incrementer++;
    });

    return <Fragment>
      {circleArray}

      <svg style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
      }}
        // key={`svg_${Math.random()}`}
      >
        {connectorLines}
      </svg>
    </Fragment>;
  };

  render() {
    let {
      models,
      loading
    } = this.state;

    if (loading) {
      return <Loader/>;
    }

    return this.circle(models, 150);
  }
}

export default withRouter(DatamodelMap);