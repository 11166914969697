import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';

export default class BreadCrumbBar extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    this.setState({
      loading: false
    });
  }

  render() {
    return (
        <AppBar
          position="fixed"
          className={'AppBarTop'}
          color="transparent"
          style={{
            padding: '10px',
            zIndex: '9999',
            fontSize: '12px',
            backgroundColor: '#ffffff38',
            height: '43px'
          }}
        >
        </AppBar>
    );
  }
};
