import React, { Component } from 'react';

import DomainDot from '../../Molecules/domainDot';

export default class AlignedTopCenterIcon extends Component {
  state = {
    componentWidth: 0,
    componentHeight: 0,
  }
  
  componentDidMount() {
    this.setState({
      componentWidth: window.innerWidth,
      componentHeight: window.innerHeight,
    });
  }

  render() {
    const {
      componentWidth
    } = this.state;

    return (
      <DomainDot
      key={`centric_domain_dot_`}
      type='centric'
      top={50}
      width={40}
      height={40}
      icon={this.props.children}
      style={{
        backgroundColor: '#f50057',
        boxShadow: '1px 1px 6px rgba(0, 0, 0, 0.70)',
        color: 'white',
        zIndex: 9999,
        position: 'fixed',
        left: `${(componentWidth / 2) - (55 / 2) }px`
      }}
      />
    );
  }
};
