
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '../../Atoms/grid';
import AppHeading from '../../Atoms/appHeading';
import Navigation from '../../Molecules/navigation';
import DashboardCards from '../../Molecules/Cards/dataModelCards';
import DefaultTemplate from '../../Templates/defaultTemplate';

class DatamodelExplorer extends Component {
  state = {
  };

  render() {
    return (
      <DefaultTemplate
      title='dashboard'
      fixedLogo
      >
        <AppHeading
        text={'dashboard'}
        />
        <Grid container>
          <Navigation/>
        </Grid>
        <Grid container>
          <DashboardCards/>
        </Grid>
      </DefaultTemplate>
    );
  }
}

export default withRouter(DatamodelExplorer);
