
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import EmptySpaceBox from '../Atoms/emptySpaceBox';
import DatamodelDomainExplorerMap from '../Molecules/datamodelDomainExplorerMap';

class ExploreDomain extends Component {
  render() {
    const {
      domain
    } = this.props;

    const rootstyle = {
      padding: '90px 20px 20px 20px',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: '0',
      left: '0',
      boxSizing: 'border-box',
    }

    return (
      <div style={rootstyle}>
        <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={3}
        >
          <Grid
          item
          xs={12}
          sm={3}
          >
            <EmptySpaceBox />
          </Grid>
          <Grid
          item
          xs={12}
          sm={3}
          >
            <EmptySpaceBox />
          </Grid>
          <Grid
          item
          xs={12}
          sm={12}
          >
            <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            spacing={3}
            >
              <DatamodelDomainExplorerMap
              domain={domain}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(ExploreDomain);
