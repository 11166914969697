import React, { Component } from 'react';

import LinkIcon from '@material-ui/icons/Link';
import CreateIcon from '@material-ui/icons/Create';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import InsertChartOutlinedTwoToneIcon from '@material-ui/icons/InsertChartOutlinedTwoTone';

export default class TableTypeIcon extends Component {
  icon(tableType) {
    const {
      style
    } = this.props;

    switch (tableType) {
      case 'transactions':
        return <InsertChartOutlinedTwoToneIcon style={{...{color: '#f50057'},...style}} />;
      case 'comments':
        return <SpeakerNotesIcon style={{...{color: '#0081e6'},...style}} />;
      case 'plannings':
        return <CreateIcon style={{...{color: '#0081e6'},...style}} />;
      case 'infos':
        return <InfoOutlinedIcon style={{...{color: '#0081e6'},...style}} />;
      case 'links':
        return <LinkIcon style={{...{color: '#ff8400'},...style}} />;
      case 'link_metrics':
      case 'info_metrics':
        return <ShowChartIcon style={{...{color: '#ff8400'},...style}} />;
      case 'metrics':
        return <TrackChangesIcon style={{...{color: 'purple'},...style}} />;
      default:
        throw new Error(`Missing Icon for table type ${tableType}`);
    }
  }

  render() {
    const {
      tableType
    } = this.props;

    return this.icon(tableType);
  }
};
