import React, { Component } from 'react';

import LinkIcon from '@material-ui/icons/Link';
import MinimizeIcon from '@material-ui/icons/Minimize';
import MaximizeIcon from '@material-ui/icons/Maximize';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import DateRangeIcon from '@material-ui/icons/DateRange';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import ExposurePlus1Icon from '@material-ui/icons/ExposurePlus1';

export default class DataTypeIcon extends Component {
  icon(action) {
    const {
      style
    } = this.props;

    switch (action) {
      case 'sum':
        return <ShowChartIcon style={style} />;
      case 'avg':
        return <LinearScaleIcon style={style} />;
      case 'count':
        return <ExposurePlus1Icon style={style} />;
      case 'min':
        return <MinimizeIcon style={style} />;
      case 'max':
        return <MaximizeIcon style={style} />;
      case 'link':
        return <LinkIcon style={style} />;
      case 'count_unique':
        return <FingerprintIcon style={style} />;
      case 'show':
        return <VisibilityIcon style={style} />;
      case 'timerange':
        return <DateRangeIcon style={style} />;
      case 'filter':
        return <DateRangeIcon style={style} />;
      case 'group':
        return <GroupWorkIcon style={style} />;
      default:
        throw new Error(`Missing Icon for action ${action}`);
    }
  }

  render() {
    const {
      action
    } = this.props;

    return this.icon(action);
  }
};
