import React, { Component, Fragment } from 'react';
import DomainDot from '../../Molecules/domainDot';

export default class AlignTopCenter extends Component {
  state = {
    componentWidth: 0,
    componentHeight: 0,
  }

  componentDidMount() {
    this.setState({
      componentWidth: window.innerWidth,
      componentHeight: window.innerHeight,
    });
  }

  render() {
    const {
      componentWidth
    } = this.state;
    const LinePositiontop = 20;

    return (
      <Fragment>
        <svg style={{
          width: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 0,
        }}
          key={`svg_centric_domain_dot_`}
        >
          <line
            key={`svg_centric_domain_dot__line_`}
            x1={(componentWidth / 2)}
            y1={100}
            x2={(componentWidth / 2)}
            y2={LinePositiontop}
            stroke="rgba(255,255,255, 0.8)"
            strokeLinecap="round"
            strokeWidth="0.5"
            fill="none"
          />

          {/* <line
            key={`svg_centric_domain_dot__line_`}
            x1={(componentWidth / 2)}
            y1={100}
            x2={(componentWidth / 2) - 120}
            y2={LinePositiontop + 45}
            stroke="rgba(255,255,255, 0.8)"
            strokeLinecap="round"
            strokeWidth="0.5"
            strokeDasharray="2,2"
            fill="none"
          />
          <line
            key={`svg_centric_domain_dot__line_`}
            x1={(componentWidth / 2)}
            y1={100}
            x2={(componentWidth / 2) - 350}
            y2={LinePositiontop + 45}
            stroke="rgba(255,255,255, 0.8)"
            strokeLinecap="round"
            strokeWidth="0.5"
            strokeDasharray="2,2"
            fill="none"
          /> */}
        </svg>

        <DomainDot
          key={`centric_domain_dot_`}
          type='centric'
          top={20}
          width={40}
          height={40}
          icon={this.props.children}
          style={{
            backgroundColor: '#ffffff2e',
            boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.30)',
            zIndex: 99999,
            position: 'fixed',
            color: 'white',
            left: `${(componentWidth / 2) - (40 / 2)}px`
          }}
        />
      </Fragment>

    );
  }
};
