import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import superagent from 'superagent';

import Hint from '../Atoms/hint';
import Grid from '../Atoms/grid';
import HomeButton from '../Atoms/homeButton';
import BackButton from '../Atoms/backButton';
import MainBackground from '../Atoms/mainBackground';
import Navigator from '../Molecules/navigator';
import DataModelMap from '../Molecules/dataModelMap';
import AuthModal from '../Molecules/Modals/authModal';
import UserAccountInteractions from '../Molecules/userAccountInteractions';

class DefaultTemplate extends Component {
  state = {
    user: false,
    jsonWebToken: false
  }

  info() {
    let path = '';
    let domain = '';
    let browserName = '';
    let operatingSystem = '';
    
    if (window.navigator) {
      browserName = window.navigator.vendor
    }

    if (
      window.navigator
      && window.navigator.userAgentData
    ) {
      operatingSystem = window.navigator.userAgentData.platform
    }

    if (
      window.location
      && window.location.origin
    ) {
      domain = window.location.origin;
    }

    if (
      window.location
      && window.location.pathname
    ) {
      path = window.location.pathname;
    }

    const jsonWebToken = localStorage.getItem('jsonWebToken');

    const info = {
      urls_dp_url_domains: domain,
      urls_dp_url_pathes: path,
      users_dp_user_checked_ins: jsonWebToken ? 'yes' : 'no',
      users_dp_user_tokens: jsonWebToken ? jsonWebToken: null,
      events_dp_event_names: 'view',
      browsers_dp_browser_names: browserName,
      operatingsystems_dp_operatingsystem_platforms: operatingSystem,
      metrics_dp_posted_ats: new Date().toISOString(),
      metrics_dp_application_loadtimes: 0
    };

    return info;
  }

  componentWillReceiveProps() {
    this.postWebMetrics();
  }

  postWebMetrics() {
    const {
      REACT_APP_RECEIVER_API
    } = process.env;

    superagent.post(`${REACT_APP_RECEIVER_API}/v-1-dp-web-dp-metrics`)
    .send(this.info())
    .end((err, res) => {

    });
  }

  updateValue(name, value) {
    localStorage.setItem(name, value);

    this.setState({
      [name]: value
    });
  }

  componentDidMount() {
    const {
      REACT_APP_APPLICATION_NAME,
    } = process.env;

    document.title = REACT_APP_APPLICATION_NAME;

    let user = localStorage.getItem('user');
    let jsonWebToken = localStorage.getItem('jsonWebToken');

    this.postWebMetrics();
  
    if (user && jsonWebToken) {
      this.setState({
        user: JSON.parse(user),
        jsonWebToken: jsonWebToken
      });
    }

  }

  valuetext(value) {
    return `${value} Inverted`;
  }

  render() {
    let {
      children,
      title,
      history
    } = this.props;
    const {
      user,
      jsonWebToken
    } = this.state;

    if(!title) {
      title = '';
    }

    return (
      <div>
        <Navigator/>
        <AuthModal/>

        <Grid wrapper>
          {/* <BackButton/> */}
          <HomeButton/>
          {/* <DataModelMap
          style={{
            // position: 'static',
            zIndex: 1,
          }}
          top={500}
          radius={150}
          position='center'
          domain={'network'}
          group='infos'
          /> */}
          {!user && !jsonWebToken ? <Hint text='Please Login' type='error'/> : children}
        </Grid>
        <UserAccountInteractions/>
        {/* <MainBackground/> */}
      </div>
    );
  }
}

export default withRouter(DefaultTemplate);
