import querystringify from 'querystringify';

function getRightFilter(key, value) {
  // TODO: THIS FUNCTION IS OBSOLETE
  if ([
    '_sort',
    '_limit',
    '_start',
    'editable'
  ].includes(key)) {
    return key;
  }

  if (Array.isArray(value)) {
    return key;
  }

  return key;
}

function getQueryParams() {
  return parseParams(this.props.location.search);
}

function setQueryParam(obj) {
  const getQueryParams = parseParams(this.props.location.search);
  let filterFor = {};

  Object.keys(obj).forEach(key => {
    const filter = getRightFilter(key, obj[key]);

    filterFor[filter] = obj[key]
  });

  filterFor = {
    ...getQueryParams,
    ...filterFor
  };

  this.props.history.push({
    search: `?${querystringify.stringify(filterFor)}`,
  });
}

// function clearObject(obj) {
//   Object.keys(obj).forEach((key) => {
//     if (
//       obj[key] === ''
//       || obj[key] === defaultValue(key)
//     ) {
//       delete obj[key];
//     }
//   });

//   return obj;
// }

// function defaultValue(key) {
//   switch (key) {
//     // case '_sort':
//     //   return 'item_no:asc';
//     case '_limit':
//       return 25;
//     case '_start':
//       return 0 / defaultValue('_limit');
//     case 'editable':
//       return false;
//     case '_show_filters':
//       return false;
//     default:
//       return '';
//   }
// }

function parseParams(paramsString) {
  const returnQuery = {};
  let query = querystringify.parse(paramsString);

  Object.keys(query).forEach(key => {
    const split = query[key].split(',');

    returnQuery[key] = split;

    if (split.length === 1) {
      returnQuery[key] = split[0];
    }
  });

  if (Object.keys(returnQuery).length === 0) {
    return false;
  }

  return returnQuery;
}

function deleteParam(paramKey) {
  const returnQuery = {};
  const getQueryParams = parseParams(this.props.location.search);

  Object.keys(getQueryParams).forEach(key => {
    if (key !== paramKey) {
      returnQuery[key] = getQueryParams[key]
    }
  });

  this.props.history.push({
    search: `?${querystringify.stringify(returnQuery)}`,
  });
}

function deleteAllParams() {
  this.props.history.push({
    search: ``,
  });
}

export {
  getQueryParams,
  setQueryParam,
  deleteParam,
  deleteAllParams,
}