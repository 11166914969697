import React, { Component } from 'react';
import Alert from '@material-ui/lab/Alert';

export default class ErrorModal extends Component {
    render() {
      const {
        errorMessage
      } = this.props;

      return (
        <div
          style={{
            maxWidth: '300px',
            margin: 'auto'
          }}
        >
          <Alert severity="error">
            { errorMessage }
          </Alert>
        </div>
      );
    } 
}