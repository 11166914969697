import React from 'react';

function HomeIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 55 55"
      width={props.width}
    >
      <g>
        <line
          style={props.strokeStyle}
          x1="40.02"
          y1="35.48"
          x2="35.02"
          y2="35.48"
        />
        <line
          style={props.strokeStyle}
          x1="41.52"
          y1="36.48"
          x2="41.52"
          y2="48.98"
        />
        <line
          style={props.strokeStyle}
          x1="28.02"
          y1="3.61"
          x2="49.02"
          y2="25.61"
        />
        <line
          style={props.strokeStyle}
          x1="26.52"
          y1="3.48"
          x2="5.52"
          y2="25.48"
        />
        <line
          style={props.strokeStyle}
          x1="52.35"
          y1="52.63"
          x2="2.85"
          y2="52.63"
        />
      </g>
    </svg>
  );
}

export default HomeIcon;
