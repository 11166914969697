import React, { Component } from 'react';
import { withRouter } from 'react-router';
import superagent from 'superagent';

import {
  getQueryParams,
  setQueryParam,
  deleteAllParams
} from '../../Utils/query';

import '../../Assets/Stylesheets/_pagination.scss';

import Loader from '../Atoms/loader';
import Typography from '../Atoms/typography';
import ErrorModal from '../Molecules/Modals/errorModal';

class Pagination extends Component {
  constructor(props) {
    super(props);

    this.setQueryParam = setQueryParam.bind(this);
    this.getQueryParams = getQueryParams.bind(this);
    this.deleteAllParams = deleteAllParams.bind(this);
  }
  
  state = {
    pagesCount: 0,
    currentPage: 0,
    currentStart: 0,
    countResults: 0,
    paginationCount: 0,
    paginationLimit: 100,
    paginationError: false,
    paginationLoading: true
  };

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.setState({
        paginationLoading: true,
        paginationCount: 0
      }, () => this.loadData());
    });

    this.loadData();
  }

  componentWillUnmount() {
    this.unlisten();
  }

  loadData() {
    const {
      REACT_APP_PRESENTER_API
    } = process.env;
    const {
      url
    } = this.props;
    const {
      paginationLimit
    } = this.state;
    const queryParams = this.getQueryParams();

    let jsonWebToken = localStorage.getItem('jsonWebToken');

    if (!jsonWebToken) {
      this.setState({
        loading: false,
        error: 'You are not logged in....'
      });
    }

    if (jsonWebToken) {
      superagent.get(`${REACT_APP_PRESENTER_API}/${url}/count${this.props.history.location.search}`)
        .set('Authorization', `Bearer ${jsonWebToken}`)
        .end((err, res) => {
          if (err) {
            this.setState({
              paginationError: err.message,
              paginationLoading: false
            });

            return;
          }

          this.setState({
            paginationCount: res.body[0].count,
            paginationLoading: false,
            paginationError: false,
            pagesCount: Math.round(res.body[0].count / paginationLimit),
            currentStart: queryParams._start ? queryParams._start : 0,
            paginationLimit: queryParams._limit ? queryParams._limit : 100,
            countResults: res.body[0].count
          });
        });
    }
  }

  generateSelectArray(pages) {
    const {
      pagesCount,
      currentPage,
      currentStart,
      paginationLimit
    } = this.state;
    pages = pagesCount / paginationLimit;

    const result = [];

    result.push(
      <option
      value={currentStart / paginationLimit}
      key={`optionCurrentPage`}>
        Current Page: {parseInt(currentStart / paginationLimit)}
      </option>
    );

    for (let i = 0; i < pages; ++i) {
      result.push(
        <option
          value={i * 100}
          key={`option${i * 100}`}
        >
          Go to Page: {i * 100}
        </option>
      );
    }

    return <select
      onChange={(e) => this.setPage(
        parseInt(e.target.value)
      )}
      key={`paginationSelect${Math.random()}`}
    >
      {result}
    </select>;
  }

  generateLimit(pages) {
    const {
      paginationLimit
    } = this.state;

    return <select
      onChange={(e) => this.setLimit(parseInt(e.target.value))}
      key={`paginationLimit${Math.random()}`}
      defaultValue={paginationLimit}
    >
      <option value={10} key={`optionLimit${10}`}>Limit: 10</option>
      <option value={25} key={`optionLimit${25}`}>Limit: 25</option>
      <option value={50} key={`optionLimit${50}`}>Limit: 50</option>
      <option value={100} key={`optionLimit${100}`}>Limit: 100</option>
      <option value={200} key={`optionLimit${200}`}>Limit: 200</option>
      <option value={500} key={`optionLimit${500}`}>Limit: 500</option>
    </select>;
  }

  setPage(page) {
    let {
      paginationLimit
    } = this.state;
    page = page * parseInt(paginationLimit);

    this.setState({
      currentPage: page,
      currentStart: page
    }, () => this.setQueryParam({ _start: page}));
  }

  setMaxPage() {
    const {
      paginationLimit,
      countResults
    } = this.state;

    const results = parseInt(countResults);
    const limit = parseInt(paginationLimit);
    const start = results - limit;

    this.setState({
      currentPage: start,
      currentStart: start
    }, () => this.setQueryParam({ _start: start }));    
  }

  setMinPage() {
    this.setState({
      currentPage: 0,
      currentStart: 0
    }, () => this.setQueryParam({ _start: 0 }));    
  }

  substractPage(add) {
    const {
      paginationLimit,
      currentStart
    } = this.state;

    let start = parseInt(currentStart);
    let limit = parseInt(paginationLimit);

    start = start - limit;

    if (add === 10) {
      start = parseInt(currentStart) - (limit * 10);
    }

    this.setState({
      currentPage: start,
      currentStart: start
    }, () => this.setQueryParam({ _start: start }));
  }

  addPage(add) {
    const {
      paginationLimit,
      currentStart
    } = this.state;

    let start = parseInt(currentStart);
    let limit = parseInt(paginationLimit);
    
    start = start + limit;

    if (add === 10) {
      start = parseInt(currentStart) + (limit * 10);
    }

    this.setState({
      currentPage: start,
      currentStart: start
    }, () => this.setQueryParam({ _start: start }));
  }

  setLimit(limit) {
    const {
      countResults
    } = this.state;

    this.setState({
      paginationLimit: parseInt(limit),
      pagesCount: Math.ceil(countResults / parseInt(limit))
    }, () => this.setQueryParam({ _limit: parseInt(limit) }));
  }

  paginationSkeleton() {
    const {
      paginationLimit,
      paginationCount,
      countResults,
      currentStart
    } = this.state;
    const pages = parseInt(countResults / paginationLimit);
    const currentPage = parseInt(currentStart / paginationLimit);

    return <div
      className='paginationContainer'
      key={`paginationContainer${Math.random()}`}
    >
      <div
        className='pagination'
      >
        <div
          className='button'
          onClick={() => this.setMinPage()}
        >
          <Typography>
            First Page: 1
          </Typography>
        </div>
        {
          currentPage >= 1 ? <div
            className='button'
            onClick={() => this.substractPage(1)}
          >
            <Typography>
              Previous Page
            </Typography>
          </div> : null
        }
        {
          currentPage >= 10 ? <div
            className='button'
            onClick={() => this.substractPage(10)}
          >
            <Typography>
              - 10 Pages
            </Typography>
          </div> : null
        }
        <div
          className='selectContainer'
        >
          {this.generateSelectArray(pages)}
        </div>
        <div
          className='button'
          onClick={() => this.addPage(10)}
        >
          <Typography>
            + 10 Pages
          </Typography>
        </div>
        <div
          className='button'
          onClick={() => this.addPage(1)}
        >
          <Typography>
            Next Page
          </Typography>
        </div>
        <div
          className='button'
          onClick={() => this.setMaxPage()}
        >
          <Typography>
            Last Page: {countResults}
          </Typography>
        </div>
        <div
          className='limitContainer'
        >
          {this.generateLimit(paginationLimit)}
        </div>
        <div className='clear' />
      </div>
      <div className='clear' />
      <div
        className='paginationInformation'
      >
        Rows: {paginationCount}, Max Rows: {paginationLimit}, Current Page: {currentPage}, Start: {currentStart}
      </div>
    </div>
  }

  render() {
    const {
      paginationCount,
      paginationError
    } = this.state;

    const returnDiv = [];

    if (paginationError) {
      returnDiv.push(
        <ErrorModal
        key={`errorModal${Math.random()}`}
          errorMessage={`😒 ${paginationError}`}
        />
      );
    }

    if (
      paginationCount === 0
      && !paginationError
    ) {
      returnDiv.push(
        <Loader
        key={`paginationLoader${Math.random()}`}
        />
      );
    }

    if (paginationCount > 0) {
      returnDiv.push(
        this.paginationSkeleton()
      );
    }

    return returnDiv;
  }
}

export default withRouter(Pagination);