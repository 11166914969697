import React, { Component } from 'react';

import HomeIcon from '../Atoms/Icons/home';
import BackIcon from '../Atoms/Icons/back';

import {
  CheckCircleIcon,
  FingerPrintIcon,
  ShieldCheckIcon,
} from '@heroicons/react/outline';

export default class Icon extends Component {
  icon(icon) {
    const {
      style,
      width
    } = this.props;

    const strokeStyle = {
      fill:'none',
      stroke:'#000',
      strokeMiterlimit:10
    }

    switch (icon) {

      case 'time':
        return <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55"><path d="M27.5,43A15.5,15.5,0,1,1,43,27.5,15.51,15.51,0,0,1,27.5,43Zm0-30A14.5,14.5,0,1,0,42,27.5,14.51,14.51,0,0,0,27.5,13Z"/><path d="M35,29.25H27.5a.5.5,0,0,1-.5-.5V17.5a.5.5,0,0,1,1,0V28.25h7a.5.5,0,0,1,0,1Z"/></svg>;
      case 'health':
        return <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55"><path d="M27.5,47.55a2.23,2.23,0,0,1-1.28-.4C16,40.21,5.55,31.4,5.68,18.7A11.25,11.25,0,0,1,16.74,7.45c6.05,0,9.58,4.7,10.76,6.6,1.18-1.9,4.71-6.6,10.76-6.6A11.25,11.25,0,0,1,49.32,18.7C49.45,31.4,39,40.21,28.78,47.15A2.23,2.23,0,0,1,27.5,47.55ZM16.74,8.45A10.25,10.25,0,0,0,6.68,18.71c-.13,12.23,10.09,20.82,20.1,27.61a1.26,1.26,0,0,0,1.44,0c10-6.79,20.23-15.38,20.1-27.61A10.24,10.24,0,0,0,38.26,8.45C31.46,8.45,28,15.22,28,15.29a.53.53,0,0,1-.9,0C27,15.22,23.54,8.45,16.74,8.45Z"/></svg>;
      case 'home':
        return <HomeIcon width={width} strokeStyle={strokeStyle}/>;
      case 'back':
        return <BackIcon strokeStyle={strokeStyle}/>;
      case 'navigator':
        return <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55"><defs></defs><g id="Plus"><line style={strokeStyle} x1="27.27" y1="30.19" x2="27.27" y2="8.48"/><circle style={strokeStyle} cx="27.27" cy="37.32" r="4.75"/></g></svg>;
      case 'log':
        return <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55"><path d="M44.52,18.07H19a.5.5,0,0,1,0-1H44.52a.5.5,0,0,1,0,1Z"/><path d="M44.52,28H19a.5.5,0,1,1,0-1H44.52a.5.5,0,1,1,0,1Z"/><path d="M44.52,37.93H19a.5.5,0,0,1,0-1H44.52a.5.5,0,0,1,0,1Z"/><path d="M11.9,19.49a1.92,1.92,0,1,1,1.92-1.92A1.92,1.92,0,0,1,11.9,19.49Zm0-2.84a.92.92,0,1,0,.92.92A.92.92,0,0,0,11.9,16.65Z"/><path d="M11.9,29.42a1.92,1.92,0,1,1,1.92-1.92A1.92,1.92,0,0,1,11.9,29.42Zm0-2.84a.92.92,0,1,0,.92.92A.92.92,0,0,0,11.9,26.58Z"/><path d="M11.9,39.35a1.92,1.92,0,1,1,1.92-1.92A1.92,1.92,0,0,1,11.9,39.35Zm0-2.84a.92.92,0,1,0,.92.92A.92.92,0,0,0,11.9,36.51Z"/></svg>;
      case 'web':
        return <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55"><path style={strokeStyle} d="M27.5,12.57A14.93,14.93,0,1,0,42.43,27.5,14.94,14.94,0,0,0,27.5,12.57Z"/><path style={strokeStyle} d="M27.5,12.57c-4.17,0-8.09,6.69-8.09,14.93s3.92,14.93,8.09,14.93,8.09-6.69,8.09-14.93S31.67,12.57,27.5,12.57Z"/><path style={strokeStyle} d="M17.55,17.55a17.47,17.47,0,0,0,19.9,0"/><path style={strokeStyle} d="M37.45,37.45a17.47,17.47,0,0,0-19.9,0"/><line style={strokeStyle} x1="27.5" y1="12.57" x2="27.5" y2="42.43"/><line style={strokeStyle} x1="42.43" y1="27.5" x2="12.57" y2="27.5"/></svg>;
      case 'date':
        return <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55"><path d="M38.49,42.29h-22a3.8,3.8,0,0,1-3.8-3.8V18.71a3.8,3.8,0,0,1,3.8-3.8h22a3.8,3.8,0,0,1,3.8,3.8V38.49A3.8,3.8,0,0,1,38.49,42.29Zm-22-26.38a2.8,2.8,0,0,0-2.8,2.8V38.49a2.8,2.8,0,0,0,2.8,2.8h22a2.8,2.8,0,0,0,2.8-2.8V18.71a2.8,2.8,0,0,0-2.8-2.8Z"/><path d="M18.7,15.91a.51.51,0,0,1-.5-.5v-2.2a.5.5,0,0,1,.5-.5.5.5,0,0,1,.5.5v2.2A.5.5,0,0,1,18.7,15.91Z"/><path d="M36.29,15.91a.51.51,0,0,1-.5-.5v-2.2a.5.5,0,0,1,.5-.5.5.5,0,0,1,.5.5v2.2A.5.5,0,0,1,36.29,15.91Z"/><path d="M41.79,21.4H13.21a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H41.79a.5.5,0,0,1,.5.5A.5.5,0,0,1,41.79,21.4Z"/><path d="M34.1,37.62a.5.5,0,0,1-.5-.5V26.33l-2.51,1.85a.49.49,0,0,1-.69-.11.5.5,0,0,1,.1-.7l3-2.2a.53.53,0,0,1,.3-.09h.32a.5.5,0,0,1,.5.5V37.12A.5.5,0,0,1,34.1,37.62Z"/><path d="M23.09,31.48a.51.51,0,0,1-.5-.5.5.5,0,0,1,.5-.5,3.41,3.41,0,0,0,2.17-.91,2.14,2.14,0,0,0,.62-1.52c0-1.56-1.4-2.25-2.79-2.25a2.44,2.44,0,0,0-2.17,1.06.5.5,0,0,1-.86-.51,3.44,3.44,0,0,1,3-1.55c2.23,0,3.79,1.33,3.79,3.24A3.11,3.11,0,0,1,26,30.27,4.35,4.35,0,0,1,23.09,31.48Z"/><path d="M23.16,37.89a4.09,4.09,0,0,1-3.44-2,.5.5,0,0,1,.17-.68.51.51,0,0,1,.69.17,3.14,3.14,0,0,0,2.58,1.53c1.54,0,3.08-.77,3.08-2.51a2.61,2.61,0,0,0-.65-1.85,3.89,3.89,0,0,0-2.5-1.05.51.51,0,0,1-.5-.5.5.5,0,0,1,.5-.5,4.87,4.87,0,0,1,3.22,1.35,3.55,3.55,0,0,1,.93,2.55C27.24,36.45,25.57,37.89,23.16,37.89Z"/></svg>;
      case 'news':
        return <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55"><defs></defs><g id="News"><line style={strokeStyle} x1="18.02" y1="27.48" x2="34.02" y2="27.48"/><line style={strokeStyle} x1="18.02" y1="36.48" x2="30.02" y2="36.48"/><line style={strokeStyle} x1="17.76" y1="18.19" x2="37.68" y2="18.19"/></g></svg>;
      case 'integer':
        return <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55"><path d="M35,41.93H20a2.64,2.64,0,0,1-2.64-2.64V15.71A2.64,2.64,0,0,1,20,13.07H35a2.64,2.64,0,0,1,2.64,2.64V39.29A2.64,2.64,0,0,1,35,41.93ZM20,14.07a1.64,1.64,0,0,0-1.64,1.64V39.29A1.64,1.64,0,0,0,20,40.93H35a1.64,1.64,0,0,0,1.64-1.64V15.71A1.64,1.64,0,0,0,35,14.07Z"/><path d="M33.93,22.64H21.07a.5.5,0,0,1-.5-.5V17.86a.5.5,0,0,1,.5-.5H33.93a.5.5,0,0,1,.5.5v4.28A.5.5,0,0,1,33.93,22.64Zm-12.36-1H33.43V18.36H21.57Z"/><path d="M21.61,29.07A2.11,2.11,0,1,1,23.71,27,2.1,2.1,0,0,1,21.61,29.07Zm0-3.21a1.11,1.11,0,1,0,1.1,1.1A1.11,1.11,0,0,0,21.61,25.86Z"/><path d="M27.5,29.07A2.11,2.11,0,1,1,29.61,27,2.11,2.11,0,0,1,27.5,29.07Zm0-3.21A1.11,1.11,0,1,0,28.61,27,1.11,1.11,0,0,0,27.5,25.86Z"/><path d="M33.39,29.07A2.11,2.11,0,1,1,35.5,27,2.1,2.1,0,0,1,33.39,29.07Zm0-3.21a1.11,1.11,0,0,0,0,2.21,1.11,1.11,0,1,0,0-2.21Z"/><path d="M21.61,34.43a2.11,2.11,0,1,1,2.1-2.11A2.11,2.11,0,0,1,21.61,34.43Zm0-3.22a1.11,1.11,0,1,0,1.1,1.11A1.11,1.11,0,0,0,21.61,31.21Z"/><path d="M27.5,34.43a2.11,2.11,0,1,1,2.11-2.11A2.12,2.12,0,0,1,27.5,34.43Zm0-3.22a1.11,1.11,0,1,0,1.11,1.11A1.11,1.11,0,0,0,27.5,31.21Z"/><path d="M21.61,39.79a2.11,2.11,0,1,1,2.1-2.11A2.11,2.11,0,0,1,21.61,39.79Zm0-3.22a1.11,1.11,0,1,0,1.1,1.11A1.11,1.11,0,0,0,21.61,36.57Z"/><path d="M27.5,39.79a2.11,2.11,0,1,1,2.11-2.11A2.12,2.12,0,0,1,27.5,39.79Zm0-3.22a1.11,1.11,0,1,0,1.11,1.11A1.11,1.11,0,0,0,27.5,36.57Z"/><path d="M33.39,39.79a2.11,2.11,0,0,1-2.1-2.11V32.32a2.11,2.11,0,1,1,4.21,0v5.36A2.11,2.11,0,0,1,33.39,39.79Zm0-8.58a1.11,1.11,0,0,0-1.1,1.11v5.36a1.11,1.11,0,1,0,2.21,0V32.32A1.11,1.11,0,0,0,33.39,31.21Z"/></svg>;
      case 'add':
      case 'plus':
        return <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55"><defs></defs><g id="Plus"><line style={strokeStyle} x1="52.35" y1="28.63" x2="2.85" y2="28.63"/><line style={strokeStyle} x1="27.52" y1="2.48" x2="27.52" y2="51.98"/></g></svg>;
      case 'minus':
        return <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55"><defs></defs><g id="Minus"><line style={strokeStyle} x1="52.35" y1="28.63" x2="2.85" y2="28.63"/></g></svg>;
      case 'dashboard':
        return <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55"><path d="M16.08,19.59a3.51,3.51,0,1,1,3.51-3.51A3.51,3.51,0,0,1,16.08,19.59Zm0-6a2.51,2.51,0,1,0,2.51,2.5A2.5,2.5,0,0,0,16.08,13.58Z"/><path d="M27.5,19.59A3.51,3.51,0,1,1,31,16.08,3.51,3.51,0,0,1,27.5,19.59Zm0-6a2.51,2.51,0,1,0,2.5,2.5A2.5,2.5,0,0,0,27.5,13.58Z"/><path d="M38.92,19.59a3.51,3.51,0,1,1,3.5-3.51A3.52,3.52,0,0,1,38.92,19.59Zm0-6a2.51,2.51,0,1,0,2.5,2.5A2.51,2.51,0,0,0,38.92,13.58Z"/><path d="M16.08,31a3.5,3.5,0,1,1,3.51-3.5A3.5,3.5,0,0,1,16.08,31Zm0-6a2.5,2.5,0,1,0,2.51,2.5A2.5,2.5,0,0,0,16.08,25Z"/><path d="M27.5,31A3.5,3.5,0,1,1,31,27.5,3.5,3.5,0,0,1,27.5,31Zm0-6A2.5,2.5,0,1,0,30,27.5,2.5,2.5,0,0,0,27.5,25Z"/><path d="M38.92,31a3.5,3.5,0,1,1,3.5-3.5A3.51,3.51,0,0,1,38.92,31Zm0-6a2.5,2.5,0,1,0,2.5,2.5A2.51,2.51,0,0,0,38.92,25Z"/><path d="M16.08,42.42a3.51,3.51,0,1,1,3.51-3.5A3.5,3.5,0,0,1,16.08,42.42Zm0-6a2.51,2.51,0,1,0,2.51,2.51A2.51,2.51,0,0,0,16.08,36.41Z"/><path d="M27.5,42.42a3.51,3.51,0,1,1,3.5-3.5A3.5,3.5,0,0,1,27.5,42.42Zm0-6A2.51,2.51,0,1,0,30,38.92,2.51,2.51,0,0,0,27.5,36.41Z"/><path d="M38.92,42.42a3.51,3.51,0,1,1,3.5-3.5A3.51,3.51,0,0,1,38.92,42.42Zm0-6a2.51,2.51,0,1,0,2.5,2.51A2.52,2.52,0,0,0,38.92,36.41Z"/></svg>;
      case 'success':
        return <CheckCircleIcon style={style} />;
      case 'identifier':
      case 'user_tokens':
        return <FingerPrintIcon style={style} />;
      case 'ip':
        return <ShieldCheckIcon style={style} />;
      case 'device':
      case 'devices':
        return <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55"><path d="M40.6,36.54H14.4a2.7,2.7,0,0,1-2.89-2.46V19.46A2.7,2.7,0,0,1,14.4,17H40.6a2.7,2.7,0,0,1,2.89,2.46V34.08A2.7,2.7,0,0,1,40.6,36.54ZM14.4,18a1.72,1.72,0,0,0-1.89,1.46V34.08a1.72,1.72,0,0,0,1.89,1.46H40.6a1.72,1.72,0,0,0,1.89-1.46V19.46A1.72,1.72,0,0,0,40.6,18Z"/><path d="M45.38,38H9.62a.5.5,0,1,1,0-1H45.38a.5.5,0,0,1,0,1Z"/></svg>;
      case 'network':
      case 'home-network':
        return <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55"><defs></defs><path style={strokeStyle} d="M34.18,31.7a10.06,10.06,0,0,0-13.35,0"/><path style={strokeStyle} d="M39.51,26.37a17.59,17.59,0,0,0-24,0"/><path style={strokeStyle} d="M44.25,20.48a25.15,25.15,0,0,0-33.5,0"/><path style={strokeStyle} d="M27.5,40.91a2.8,2.8,0,1,1,2.8-2.8A2.8,2.8,0,0,1,27.5,40.91Z"/></svg>;
      case 'info':
      case 'sideInfo':
        return <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55"><path d="M27.5,41.7A14.2,14.2,0,1,1,41.7,27.5,14.22,14.22,0,0,1,27.5,41.7Zm0-27.4A13.2,13.2,0,1,0,40.7,27.5,13.21,13.21,0,0,0,27.5,14.3Z"/><path d="M27.8,34.55a.5.5,0,0,1-.5-.5V25.92H25.42a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H27.8a.5.5,0,0,1,.5.5v8.63A.5.5,0,0,1,27.8,34.55Z"/><path d="M31.07,34.85H24.52a.5.5,0,0,1,0-1h6.55a.5.5,0,0,1,0,1Z"/><path d="M27.5,23.09a2.44,2.44,0,1,1,2.44-2.44A2.45,2.45,0,0,1,27.5,23.09Zm0-3.87a1.44,1.44,0,1,0,1.44,1.43A1.44,1.44,0,0,0,27.5,19.22Z"/></svg>;
      case 'remove':
        return <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55"><g id="Remove"><line x1="44.11" y1="45.72" x2="9.11" y2="10.72" style={strokeStyle}/><line x1="45.05" y1="9.67" x2="10.04" y2="44.67" style={strokeStyle}/></g></svg>
      default:
        return <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55"><path d="M27.5,42A14.45,14.45,0,1,1,42,27.5,14.46,14.46,0,0,1,27.5,42Zm0-27.9A13.45,13.45,0,1,0,41,27.5,13.47,13.47,0,0,0,27.5,14.05Z"/><path d="M27.5,30.32a.5.5,0,0,1-.5-.47L26.58,21v0h0a.93.93,0,0,1,.91-.93,1,1,0,0,1,.69.3.93.93,0,0,1,.24.66L28,29.85A.5.5,0,0,1,27.5,30.32Z"/><path d="M27.5,36.13a2,2,0,1,1,1.95-2A2,2,0,0,1,27.5,36.13Zm0-2.91a1,1,0,1,0,.95,1A1,1,0,0,0,27.5,33.22Z"/></svg>;
    }
  }

  render() {
    const {
      icon
    } = this.props;

    return this.icon(icon);
  }
};
