import '../../Assets/Stylesheets/_typography.scss';
import PropTypes from 'prop-types';

function component(component, children) {
  switch(component) {
    case 'h1':
      return <h1>{children}</h1>;
    case 'h2':
      return <h2>{children}</h2>;
    case 'h3':
      return <h3>{children}</h3>;
    case 'h4':
      return <h4>{children}</h4>;
    case 'label':
      return <label>{children}</label>;
    case 'caption':
      return <span className='caption'>{children}</span>;
    case 'technical':
      return <span className='technical'>{children}</span>;
    case 'pre':
      return <pre>{children}</pre>;
    default:
      return <span>{children}</span>;
  }
}

export default function Typography(props) {
  const classNames = [
    'typography'
  ];

  if (props.textAlign === 'left') {
    classNames.push(
      'textAlign-left'
    );
  }

  if (props.textAlign === 'center') {
    classNames.push(
      'textAlign-center'
    );
  }

  if (props.variant) {
    classNames.push(
      props.variant
    );
  }

  return <div
  className={classNames.join(' ')}
  >
    {component(props.component, props.children)}
  </div>;
}

Typography.propTypes = {
  variant: PropTypes.string
};