import '../../Assets/Stylesheets/_hint.scss';

import Typography from '../Atoms/typography';
import Icon from '../Atoms/icon';

export default function Hint(props) {
  let text = 'You are not allowed to see this visual. please log in.';
  let type = 'info';

  if(props.text) {
    text = props.text;
  }

  if(props.type) {
    type = props.type;
  }

  return <div
  className={`hint ${type}`}
  >
    <div className='icon'>
      <Icon domain={type}/>
    </div>
    <Typography>
      {text}
    </Typography>
  </div>;
}