import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { get } from 'superagent';

import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Accordion from '@material-ui/core/Accordion';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';

import { capitalize, capitalizeAll } from '../../Utils/string';

import Paper from '../Atoms/paper';
import Loader from '../Atoms/loader';
import TableTypeIcon from '../Atoms/Icons/tableTypeIcon';

class DataModelDomainExplorerMap extends Component {
  state = this.initialState();

  initialState() {
    return {
      componentWidth: 0,
      componentHeight: 0,
      model: {},
      loading: true,
      linePositionsX: []
    };
  }

  componentDidMount() {
    this.init(
      this.props
    );
  }

  componentWillReceiveProps(nextProps) {
    this.setState(
      this.initialState(),
      () => this.init(nextProps)
    );
  }

  init(props) {
    const {
      domain
    } = props;

    const {
      REACT_APP_MODEL_API
    } = process.env;

    get(`${REACT_APP_MODEL_API}/explore/${domain}`).end((err, res) => {
      if (err) {

        return;
      }

      this.setState({
        model: res.body,
        componentWidth: window.innerWidth,
        componentHeight: window.innerHeight,
      }, () => this.setState({ loading: false }));
    });
  }

  render() {
    let {
      model,
      loading,
      linePositionsX,
      componentWidth
    } = this.state;
    const {
      domain
    } = this.props;
    const returnDivs = [];
    const connectorLines = [];

    if (loading) {
      return <Loader />;
    }

    Object.keys(model).forEach(key => {
      const {
        // config,
        attributes
      } = model[key];
      const numberOfAttributes = Object.keys(attributes).length;

      Object.keys(attributes).forEach(group => {
        const collectTables = [];

        Object.keys(attributes[group]).forEach(table => {

          collectTables.push(
            <Accordion key={`${group}_${table}`}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${group}_${table}_content`}
                id={`${group}_${table}_id`}
              >
                <TableTypeIcon
                style={{
                  marginRight: '10px'
                }}
                tableType={group}
                />
                <Typography
                variant="caption"
                style={{
                  padding: '3px 0 2px'
                }}
                >
                  {capitalizeAll(group)} {capitalizeAll(table)}
                </Typography>
              </AccordionSummary>
              
            </Accordion>
          );
        });

        returnDivs.push(
          <Grid
          key={`grid_Paper_${domain}ExplorerMap_${group}`}
          ref={ (div) => { this[`location_grid_${key}`] = div } }
          item xs={12}
          sm={12 / numberOfAttributes}
          >
            <Paper>
              <CardHeader
                avatar={
                  <Avatar
                  style={{
                    backgroundColor: 'transparent'
                  }}
                  >
                    <TableTypeIcon
                    tableType={group}
                    style={{
                      color: '#ffffff5e'
                    }}
                    />
                  </Avatar>
                }
                action={
                  <IconButton
                  onClick={() => this.props.history.push(`/explore/${domain}/${group}`)}
                  aria-label="go home"
                  >
                    <ZoomOutMapIcon />
                  </IconButton>
                }
                title={`${capitalize(domain)} ${capitalize(group)}`}
              />
              {collectTables}
            </Paper>
          </Grid>
        );
      });
    });

    // if (linePositionsX) {
    //   linePositionsX.forEach(linePositionX => {
    //     connectorLines.push(<line
    //       key={`line_${Math.random()}`}
    //       x1={linePositionX}
    //       y1={52}
    //       x2={(componentWidth / 2) + 10}
    //       y2={105}
    //       stroke="rgba(0, 0, 0, 1)"
    //     />);
    //   });
    // }

    return <Fragment>
      {returnDivs}

      {/* <svg style={{
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
      }}
        key={`svg_${Math.random()}`}
      >
        {connectorLines}
      </svg> */}
    </Fragment>
  }
}

export default withRouter(
  DataModelDomainExplorerMap
);
