import React, { Component } from 'react';
import superagent from 'superagent';

import { plugins } from '../../Utils/plugins';
import Icon from './icon';

import '../../Assets/Stylesheets/_form.scss';

class AddDevicePlugin extends Component {
  state = {
    selectedPlugin: null,
    jsonWebToken: null,
    user: null
  }

  componentDidMount() {
    let user = localStorage.getItem('user');
    let jsonWebToken = localStorage.getItem('jsonWebToken');

    if (user && jsonWebToken) {
      this.setState({
        user: JSON.parse(user),
        jsonWebToken: jsonWebToken
      });
    }
  }

  postPlugin(device_mac_adresses, plugin) {
    const {
      REACT_APP_PRESENTER_API
    } = process.env;
    const {
      jsonWebToken
    } = this.state;

    this.setState({
      loading: true
    }, () => {
      superagent.post(`${REACT_APP_PRESENTER_API}/network-dp-categories-dp-device-mappings
      `)
      .send({
        device_mac_adresses,
        plugin
      })
      .set('Authorization', `Bearer ${jsonWebToken}`)
      .end((err, res) => {
        // TODO: HANDLE ERRORS
      });
    });
  }

  dropdown() {
    const returnArray = [
      <option key='none' value='none'>None</option>
    ];

    Object.keys(plugins).forEach(plugin => {
      returnArray.push(
        <option
        value={plugin}
        key={plugin}
        >
          {plugins[plugin].name}
        </option>
      )
    });

    return (
      <select
        onChange={(e) => this.setState({
          selectedPlugin: e.target.value
        })}
        style={{
          float: 'left',
          width: '90px'
        }}
      >
        {returnArray}
      </select>
    );
  }

  addPlugin() {
    const {
      selectedPlugin
    } = this.state;
    const {
      macAdress
    } = this.props;

    this.postPlugin(macAdress, selectedPlugin);
  }

  render() {
    return (
      <div
        style={{
          width: '150px'
        }}
      >
        {this.dropdown()}
        <span
        style={{
          width: '30px',
          float: 'left',
          margin: '8px 0'
        }}
        onClick={() => 
          this.addPlugin()
        }>
          <Icon
          domain='add'
          />
        </span>
        <div className='clear'/>
      </div>
    );
  }
};

export default AddDevicePlugin;