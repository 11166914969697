import React from 'react';

import '../../Assets/Stylesheets/_paper.scss';

export default function Paper(props) {
  return (
    <div className='paper' style={props.style}>
      {props.children}
    </div>
  );
};
