import React, { Component } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

export default class Loader extends Component {
  state = {
    componentWidth: 0,
    componentHeight: 0,
  }

  componentDidMount() {
    this.setState({
      componentWidth: window.innerWidth,
      componentHeight: window.innerHeight,
    });
  }

  render () {
    // const {
    //   componentWidth
    // } = this.state;
    return (
      <div style={{
        width: '100%',
        textAlign: 'center',
      }}>
        <CircularProgress
        style={{
          height: '54px',
          width: '54px',
        }}
        disableShrink />
      </div>
    );
  }
};
