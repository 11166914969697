import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';

const useStyles = makeStyles();

function logout() {
  localStorage.clear();
  window.location.reload(false);
}

export default function ImgMediaCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Hey {props.name}
          </Typography>
          <Typography gutterBottom variant="caption" component="h2">
            Your Email: {props.email}
          </Typography>
          <Typography gutterBottom variant="caption" component="h2">
            {/* {JSON.stringify(props.role)} */}
            Your current role: {props.role ? props.role.name : null}
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {props.role ? props.role.description : null}
          </Typography>
          
        </CardContent>
      </CardActionArea>
      <Typography gutterBottom variant="caption" component="h2">
        Your API Token: <pre>{props.apiToken}</pre>
      </Typography>
      <CardActions>
        <Button
          size="small"
          color="secondary"
        >
          Settings (coming soon)
        </Button>
        <Button
          size="small"
          onClick={() => logout()}
        >
          Logout
        </Button>
      </CardActions>
    </Card>
  );
}