import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import AppHeading from '../../Atoms/appHeading';
import DataModelMap from '../../Molecules/dataModelMap';
import DefaultTemplate from '../../Templates/defaultTemplate';

class DatamodelExplorer extends Component {
  render() {
    return (
      <DefaultTemplate
        title='Explore'
        fixedLogo
      >
        {/* <BreadCrumbBar
        pageName='Explore'
        url='explore'
        /> */}
        <AppHeading
        text='Explore'
        />
        <DataModelMap
        domain='devices'
        group='metrics'
        />
      </DefaultTemplate>
    );
  }
}

export default withRouter(
  DatamodelExplorer
);
