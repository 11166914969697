import React, { Component, Fragment } from 'react';
import superagent from 'superagent';

import Grid from '../Atoms/grid';
import PluginCard from '../Molecules/pluginCard';

class DevicePlugins extends Component {
  state = {
    data: {},
    macAdressIpMapping: {},
    loading: true,
    error: false
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    const {
      REACT_APP_PRESENTER_API
    } = process.env;
    const {
      url
    } = this.props;

    let jsonWebToken = localStorage.getItem('jsonWebToken');

    if (!jsonWebToken) {
      this.setState({
        loading: false,
        error: 'You are not logged in....'
      });
    }

    if (jsonWebToken) {
      superagent.get(`${REACT_APP_PRESENTER_API}/network-dp-categories-dp-device-mappings`)
      .set('Authorization', `Bearer ${jsonWebToken}`)
      .end((err, res) => {
        if (err) {
          this.setState({
            error: err.message,
            loading: false
          });

          return;
        }

        const deviceMapping = res.body;

        superagent.get(`${REACT_APP_PRESENTER_API}/views-network/current-ips`)
        .set('Authorization', `Bearer ${jsonWebToken}`)
        .end((err, res) => {
          if (err) {
            this.setState({
              error: err.message,
              loading: false
            });

            return;
          }

          this.setState({
            data: deviceMapping,
            macAdressIpMapping: this.transformMacAdresses(res.body),
            loading: false,
            error: false
          });
        });
      });
    }
  }

  transformMacAdresses(data) {
    let returnObj = {};

    Object.keys(data).forEach(k => {
      if (!returnObj[data[k].device_mac_adresses]) {
        returnObj[data[k].device_mac_adresses] = data[k].device_internal_ips
      }
    });

    return returnObj;
  }

  plugins() {
    const {
      data,
      macAdressIpMapping
    } = this.state;
    const returnArray = [];

    Object.keys(data).forEach(k => {
      returnArray.push(
        <Grid
          columns={1} spacing={10}
          key={`devices${data[k].device_mac_adresses}`}
        >
          <PluginCard
          plugin={data[k].plugin}
          data={data[k]}
          ipAdress={macAdressIpMapping[data[k].device_mac_adresses]}
          />
        </Grid>
      );
    });

    return returnArray;
  }

  render() {
    return <Grid container>
      {this.plugins()}
    </Grid>
  }
}

export default DevicePlugins;
