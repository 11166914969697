
import React from 'react';
import { withRouter } from 'react-router';

import Grid from '../../Atoms/grid';
import KpiNumber from '../../Atoms/kpiNumber';
import Card from '../../Molecules/card';
import DataTable from '../../Molecules/dataTable';

function NetworkDashboard(props) {
  const rootstyle = {
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    boxSizing: 'border-box',
  };

  const dataTypeMapping = {
    posted_ats: 'dateTime',
    created_at: 'dateTime',
    updated_at: 'dateTime',
    count: 'integer',
    id: 'integer',
    device_names: 'device',
    device_types: 'device',
    device_vendors: 'vendor',
    device_locations: 'info',
    device_networks: 'info',
    device_internal_ips: 'ip',
    producer_ips: 'ip',
    device_mac_adresses: 'identifier',
  };

  return (
    <div style={rootstyle}>
      <Grid container>
        <Grid
          columns={2}
          spacing={10}
        >
          <Card
            title='Mac Adresses'
            description='counts all registered mac adresses'
          >
            <KpiNumber
              countDomain='network-dp-links-dp-device-mac-adresses/count'
              title='mac adresses'
            />
          </Card>
        </Grid>
        <Grid
          columns={2}
          spacing={10}
        >
          <Card
            title='Networks'
            description='counts all registered networks'
          >
            <KpiNumber
              countDomain='network-dp-links-dp-device-networks/count'
              title='networks'
            />
          </Card>
        </Grid>
        <Grid
          columns={2}
          spacing={10}
        >
          <Card
            title='IPs'
            description='counts all registered IPs'
          >
            <KpiNumber
              countDomain='network-dp-links-dp-device-internal-ips/count'
              title='ips'
            />
          </Card>
        </Grid>
        <Grid
          columns={2}
          spacing={10}
        >
          <Card
            title='Pings'
            description='counts all pings produced'
          >
            <KpiNumber
              countDomain='network-dp-transactions-dp-totals/count'
              title='pings'
            />
            <KpiNumber
              countDomain={`network-dp-transactions-dp-totals/count${props.history.location.search}`}
              title='pings'
            />
          </Card>
        </Grid>

        <Grid
          columns={12}
          spacing={10}
        >
          <Card
            title='Network Devices'
            description='Shows all tracked networks'
          >
            <DataTable
              dataTypeMapping={dataTypeMapping}
              url={'views-network/all'}
            />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default withRouter(
  NetworkDashboard
);