
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import Loader from '../../Atoms/loader';
import AppHeading from '../../Atoms/appHeading';
import ErrorModal from '../../Molecules/Modals/errorModal';
import DefaultTemplate from '../../Templates/defaultTemplate';

class NewsPage extends Component {
  state = {
    devices: false,
    loading: true,
    error: false
  };

  componentDidMount() {
    this.setState({
      error: 'Please connect your news apis here'
    });
  }

  render() {
    const {
      error
    } = this.state;

    let returnDiv = <Loader/>;

    if (error) {
      returnDiv = <ErrorModal
      errorMessage={`😒 ${error}`}
      />;
    }

    return (
      <DefaultTemplate
        title='news apis'
        fixedLogo
      >
        <AppHeading text={`News`}/>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={0}
          style={{
            paddingBottom: '20px',
            overflow: 'scroll',
            height: 'calc(100vh - 519px)'
          }}
        >
          <Grid
          container
          spacing={4}
          style={{
            maxWidth: '60%',
            margin: 'auto',
            position: 'relative',
            zIndex: '999',

          }}
          >
            <Grid
            item
            xs={12}
            sm={12}
            >
              {returnDiv}
            </Grid>
          </Grid>
        </Grid>
      </DefaultTemplate>
    );
  }
}

export default withRouter(NewsPage);
