
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '../../Atoms/grid';
import AppHeading from '../../Atoms/appHeading';
import Navigation from '../../Molecules/navigation';
import QueryFilters from '../../Molecules/queryFilters';
import Filter from '../../Organisms/filter';
import WebDashboard from  '../../Organisms/Dashboards/webDashboard';
import LogDashboard from  '../../Organisms/Dashboards/logDashboard';
import NetworkbDashboard from  '../../Organisms/Dashboards/networkDashboard';
import DevicesDashboard from  '../../Organisms/Dashboards/devicesDashboard';
import DefaultTemplate from '../../Templates/defaultTemplate';

class DatamodelDomainExplorer extends Component {
  render() {
    const {
      match
    } = this.props;
    const {
      params
    } = match;

    return (
      <DefaultTemplate
      blurred={true}
      >
        <AppHeading
        text={`Dashboard ${params.domain}`}
        />
        <Grid container>
          <Navigation/>
          <Filter domain={params.domain}/>
          <QueryFilters domain={params.domain}/>
        </Grid>

        {params.domain === 'devices' ? <DevicesDashboard/> : null}
        {params.domain === 'web' ? <WebDashboard/> : null}
        {params.domain === 'network' ? <NetworkbDashboard/> : null}
        {params.domain === 'log' ? <LogDashboard/> : null}
      </DefaultTemplate>
    );
  }
}

export default withRouter(DatamodelDomainExplorer);
