import React from 'react';

import Grid from '../Atoms/grid';
import HomepageEye from '../Atoms/homePageEye';

export default function Navigation(props) {
  // TODO: REMOVE FUNCTION
  return null;

  return (
    <Grid spacing={10}>
      <HomepageEye/>
    </Grid>
  );
}