import React, { Component } from 'react';
import NavigatorMap from '../Molecules/navigatorMap';

export default class HomeStructure extends Component {
  render() {
    return <NavigatorMap  
    style={{
      position: 'static'
    }}
    top={400}
    radius={150}
    position='center'
    domain={'devices'}
    group='metrics'
    />;
  }
}