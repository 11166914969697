import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import WhatshotIcon from '@material-ui/icons/Whatshot';

import Icon from '../Atoms/icon';
import EmptySpaceBox from '../Atoms/emptySpaceBox';
import AlignedTopCenterIcon from '../Atoms/Icons/alignedTopCenterIcon';
import AlignedTopGoBackCenterIcon from '../Atoms/Icons/alignedTopGoBackCenterIcon';
import Table from '../Molecules/table';

class ExploreDomainGroupTable extends Component {
  render() {
    const {
      domain,
      // group
    } = this.props;

    const rootstyle = {
      padding: '90px 20px 20px 20px',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: '0',
      left: '0',
      boxSizing: 'border-box',
    }

    return (
      <div style={rootstyle}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={12} sm={3}>
            <EmptySpaceBox />
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
            <AlignedTopCenterIcon>
              <Icon
                key={`centric_circle_top_${domain}`}
                icon={domain}
                style={{ fontSize: '24px' }}
              />
            </AlignedTopCenterIcon>
            <AlignedTopGoBackCenterIcon>
              <Avatar
                onClick={() => this.props.history.push(`/explore/${domain}`)}
                style={{
                  background: 'transparent',
                  display: 'inline-flex'
                }}
                key={`home_dot_${domain}`}
              >
                <WhatshotIcon />
              </Avatar>
            </AlignedTopGoBackCenterIcon>
          </Grid>
          <Grid item xs={12} sm={3}>
            <EmptySpaceBox />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-start"
              spacing={3}
            >
              <Table/>
              {/* <DatamodelDomainExplorerMapDetail domain={domain} group={group}/> */}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(ExploreDomainGroupTable);
