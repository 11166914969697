import React from 'react';
import { withRouter } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import CloseIcon from '@material-ui/icons/Close';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';

import {
  capitalize
} from '../../Utils/string';

import Paper from '../Atoms/paper';
import HomePageDots from '../Molecules/homePageDots';

function TransitionsModal(props) {
  return (
    <div style={{
      position: 'fixed',
      top: '0',
      left: '0',
      zIndex: 9999999,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 50%)',
    }}>
      <Paper 
      style={{
        margin: '20px auto',
        width: '50%',
      }}
      >
        <CardHeader
          avatar={
            props.icon ? props.icon : <Avatar style={{ backgroundColor: 'white' }}/>
          }
          style={{
            background: 'rgba(255,255,255,80%)'
          }}
          title={capitalize(props.title)}
          action={
            <IconButton
              onClick={() => {
                props.history.push({ search: '' })
              }}
            >
              <CloseIcon />
            </IconButton>
          }
        />
        {props.children}
      </Paper>
      {/* <HomePageDots/> */}
    </div>
  );
}

export default withRouter(
  TransitionsModal
);
