export function asTree(
  object,
  viewPortWidth,
  viewPortHeight,
  centricElementWidth,
  centricElementHeight,
  elementWidth,
  elementHeigth,
  distance,
  padding,
  position
) {
  const numberOfElements = Object.keys(object).length;

  const positionTop = 200;
  const positionCenter = (viewPortWidth / 2) - (centricElementWidth / 2);

  const centricElement = {
    positionTop: positionTop,
    positionLeft: positionCenter,
    elementWidth: centricElementWidth,
    elementHeight: centricElementHeight
  };
  const circleArray = [];
  const connectorLines = [];
  let stepper = 0 + padding - (elementWidth / 2);
  const step = (viewPortWidth / (numberOfElements - 1)) - (padding);

  Object.keys(object).forEach(model => {
    const positionTop2 = positionTop + (distance / 2);

    circleArray.push({
      positionTop: positionTop2,
      positionLeft: stepper,
      elementWidth: elementWidth,
      elementHeight: elementHeigth,
    });

    connectorLines.push({
      positionLeftStartPoint: stepper + (elementWidth / 2),
      positionTopStartPoint: positionTop2 + (elementHeigth / 2),
      positionLeftEndPoint: centricElement.positionLeft + (centricElementWidth / 2),
      positionTopEndPoint: centricElement.positionTop + (centricElementHeight),
    });

    stepper = stepper + step;
  });

  return {
    centricElement,
    circleElements: circleArray,
    lineElements: connectorLines
  };
}

export function asCircle(
  object,
  radius,
  outerItemsDiameter,
  currentLocationDiameter,
  componentWidth,
  componentHeight,
  position,
  top
) {
  const outerElementsDiameter = outerItemsDiameter;
  const innerElementDiameter = currentLocationDiameter;
  const frags = 360 / Object.keys(object).length;

  let locationLeft = (componentWidth / 2);

  if (position === 'left') {
    locationLeft = (componentWidth / 4);
  }

  if (position === 'right') {
    locationLeft = (componentWidth / 4) * 3;
  }

  let posx;
  let posy;
  let incrementer = 0;

  const circleArray = [];
  const connectorLines = [];

  const centricElement = {
    positionTop: top - (innerElementDiameter / 2),
    positionLeft: locationLeft - (innerElementDiameter / 2),
    elementWidth: currentLocationDiameter,
    elementHeight: currentLocationDiameter,
  }

  Object.keys(object).forEach((model) => {
    const theta = (frags / 180) * incrementer * Math.PI;

    posx = Math.round(radius * (Math.cos(theta))) + 'px';
    posy = Math.round(radius * (Math.sin(theta))) + 'px';
    
    const positionTop = top - parseInt(posy.slice(0, - 2)) - (outerElementsDiameter / 2);
    const positionLeft = (locationLeft + parseInt(posx.slice(0, - 2)) - (outerElementsDiameter / 2));
    
    let alignment = 'center'
    
    if (positionLeft < centricElement.positionLeft) {
      alignment = 'left'
    }
    
    if (positionLeft > centricElement.positionLeft) {
      alignment = 'right'
    }

    const higherThanTopElement = top - radius + (outerElementsDiameter);

    if (higherThanTopElement > positionTop) {
      alignment = 'top'
    }

    const lowerThanTopLowest = top + radius - (outerElementsDiameter);

    if (lowerThanTopLowest < positionTop) {
      alignment = 'bottom'
    }

    circleArray.push({
      positionTop: positionTop,
      positionLeft: positionLeft,
      elementWidth: outerElementsDiameter,
      elementHeight: outerElementsDiameter,
      aligned: alignment
    });

    connectorLines.push({
      positionLeftStartPoint: locationLeft,
      positionTopStartPoint: top,
      positionLeftEndPoint: positionLeft + (outerElementsDiameter / 2),
      positionTopEndPoint: positionTop + (outerElementsDiameter / 2),
    });

    incrementer++;
  });

  return {
    centricElement,
    circleElements: circleArray,
    lineElements: connectorLines
  };
};