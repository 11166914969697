import { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import superagent from 'superagent';

import Alert from '@material-ui/lab/Alert';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import AccountCircle from '@material-ui/icons/AccountCircle';
import InputAdornment from '@material-ui/core/InputAdornment';

import {
  getQueryParams,
  setQueryParam,
  deleteAllParams
} from '../../../Utils/query';

import Modal from '../../Atoms/modal';
import Loader from '../../Atoms/loader';
import UserCard from '../../Molecules/Cards/userCard';

class AuthModal extends Component {
  constructor(props) {
    super(props);

    this.setQueryParam = setQueryParam.bind(this);
    this.getQueryParams = getQueryParams.bind(this);
    this.deleteAllParams = deleteAllParams.bind(this);
  }

  state = {
    identifier: '',
    password: '',
    response: {},
    loading: false,
    jsonWebToken: null,
    user: null,
    error: null
  };

  componentDidMount() {
    let user = localStorage.getItem('user');
    let jsonWebToken = localStorage.getItem('jsonWebToken');

    if (user && jsonWebToken) {
      this.setState({
        user: JSON.parse(user),
        jsonWebToken: jsonWebToken
      });
    }
  }

  handleSubmit() {
    const {
      identifier,
      password
    } = this.state;
    const {
      REACT_APP_PRESENTER_API
    } = process.env;

    this.setState({
      loading: true,
      response: {},
      user: {},
      jsonWebToken: null
    }, () => {
      superagent.post(`${REACT_APP_PRESENTER_API}/auth/local`)
        .send({
          identifier: identifier,
          password: password,
        })
        .end((err, res) => {
          if (err) {
            console.log(err)

            this.setState({
              error: err.message
            }, () => this.refreshPage());

            return;
          }

          if (res.body.user) {
            localStorage.setItem('jsonWebToken', res.body.jwt);
            localStorage.setItem('user', JSON.stringify(res.body.user));
          }

          this.setState({
            response: res.body,
            loading: false,
            user: res.body.user,
            jsonWebToken: res.body.jwt
          }, () => this.refreshPage());
        });
    });
  }

  refreshPage(){ 
    window.location.reload(); 
  }

  render() {
    const {
      loading,
      response,
      user,
      error
    } = this.state;
    
    if(error) {
      return error;
    }

    const params = this.getQueryParams();

    if (!params.user_actions) {
      return null;
    }

    const alert = [];

    if (response.message) {
      const alertType = response.statusCode ? 'warning' : 'error';

      response.message.forEach(key =>
        key.messages.forEach(key2 => {

          alert.push(
            <Alert
              key={`${key}${key2}`}
              severity={alertType}
              style={{ margin: '10px 10px 20px' }}
            >
              {key2.message}
            </Alert>
          )
        })
      );
    }

    return <Modal
      open={true}
      title={user ? 'profile' : 'login'}
      icon={user ? <Avatar>{user.username ? `${user.username.charAt(0)} ${user.username.charAt(1)}` : ''}</Avatar> : false}
    >
      {alert}
      {loading ? <Loader key='authloader' /> : null}
      {user && !loading ? this.profile() : this.loginForm()}
    </Modal>;
  }

  profile() {
    const {
      user,
      jsonWebToken
    } = this.state;

    return <UserCard
      name={user.username}
      email={user.email}
      role={user.role}
      apiToken={jsonWebToken}
    />;
  }

  loginForm() {
    const {
      identifier,
      password,
    } = this.state;

    return (
      <Fragment>
        <div
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            margin: '10px 0 20px',
          }}
        >
          <Input
            required
            id="username-input"
            label="Username"
            placeholder="username or email"
            fullWidth
            style={{ padding: '15px 10px' }}
            value={identifier}
            onChange={(e) => this.setState({ identifier: e.target.value })}
            startAdornment={
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            }
          />
          <Input
            id="password-input"
            label="Password"
            type="password"
            value={password}
            fullWidth
            style={{ padding: '15px 10px' }}
            placeholder="password"
            onChange={(e) => this.setState({ password: e.target.value })}
            startAdornment={
              <InputAdornment position="start">
                <FingerprintIcon />
              </InputAdornment>
            }
          />
        </div>

        <Button
          variant="contained"
          onClick={() => {
            this.handleSubmit();
          }}
        >
          Login
      </Button>
      </Fragment>
    )
  }
}

export default withRouter(
  AuthModal
);
