import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import MapIcon from '@material-ui/icons/Map';
import Tooltip from '@material-ui/core/Tooltip';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import DashboardIcon from '@material-ui/icons/Dashboard';

import { capitalize } from '../../Utils/string';

class DomainDot extends Component {
  render() {
    let {
      top,
      left,
      width,
      height,
      icon,
      title,
      style,
      type,
      addIcon,
      className,
      topPositionCentric
    } = this.props;

    if (!topPositionCentric) {
      topPositionCentric = 0;
    }

    if (!title) {
      title = ''
    }

    /*
     Colors
    */
   const domainDotColor = 'rgba(255, 255, 255, 0.5)';
   const mapIconColor = 'rgb(170 5 255)';
   const dashboardIconColor = 'rgb(73 152 249)';

    return (
      <div
      className={className}
      key={`dot_${type}_${title}`}
      >
        <Tooltip
        style={{
          zIndex: 99
        }}
        title={`${capitalize(title)}`}
        placement="right"
        >
          <div
          className='dot'
          style={{...{
            top: `${top + topPositionCentric}px`,
            left: `${left}px`,
            width: `${width}px`,
            height: `${height}px`,
            marginBottom: `${30}px`
          },...style}}
          onClick={() => this.props.history.push(`/`)}
          key={`avatar_${title}`}
          >
            {icon}
          </div>
        </Tooltip>

        {addIcon === 'home' ?
          <Fragment
          key={'fragmentttt'}
          >
            <Avatar
            onClick={() => this.props.history.push(`/explore/${title}`)}
            style={{
              position: 'absolute',
              background: 'green',
              top: `${top + (height - 20)}px`,
              left: `${left + (width / 6)}px`,
              zIndex: 99
            }}
            key={`home_dot_${title}`}
            >
              <HomeWorkIcon/>
            </Avatar>
          </Fragment>
        : null}
        {type !== 'centric' ?
          <Fragment key={'fragmentttt'}>
            <Tooltip title={`${capitalize(title)} Explorer`} placement="top">
              <Avatar
              className='hover-pill'
              onClick={() => this.props.history.push(`/explore/${title}`)}
              style={{
                position: 'absolute',
                background: mapIconColor,
                top: `${top - 10}px`,
                left: `${left + 40}px`,
                zIndex: 99

              }}
              key={`explorer_dot_${title}`}
              >
                <MapIcon />
              </Avatar>
            </Tooltip>
            <Tooltip title={`${capitalize(title)} Dashboard`} placement="top">
              <Avatar
              className='hover-pill'
              onClick={() => this.props.history.push(`/dashboard/${title}`)}
              style={{
                position: 'absolute',
                background: dashboardIconColor,
                top: `${top - 10}px`,
                left: `${left - 10}px`,
                zIndex: 99
              }}
              key={`dashboard_dot_${title}`}
              >
                <DashboardIcon/>
              </Avatar>
            </Tooltip>
          </Fragment>
        : null}
      </div>
    );
  }
}

export default withRouter(
  DomainDot
);
