import React from 'react';

function BackIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 55 55"
    >
      <g>
        <line
          style={props.strokeStyle}
          x1="13.93"
          y1="27.51"
          x2="35.93"
          y2="6.51"
        />
        <line
          style={props.strokeStyle}
          x1="13.81"
          y1="29.01"
          x2="35.81"
          y2="50.01"
        />
      </g>
    </svg>
  );
}

export default BackIcon;
