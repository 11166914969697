import React, { Component } from 'react';
import { withRouter } from 'react-router';

import {
  setQueryParam,
  getQueryParams,
  deleteParam
} from '../../Utils/query';

import '../../Assets/Stylesheets/_queryFilters.scss';

import Icon from '../Atoms/icon';
import Typography from '../Atoms/typography';
import FilterPillValue from '../Atoms/filterPillValue';
import Card from '../Molecules/card';

import {
  capitalizeAll
} from '../../Utils/string';

class QueryFilters extends Component {
  constructor(props) {
    super(props);

    this.deleteParam = deleteParam.bind(this);
    this.setQueryParam = setQueryParam.bind(this);
    this.getQueryParams = getQueryParams.bind(this);
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {

    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  generateFilterPills(queryParams) {
    const returnDiv = [];

    Object.keys(queryParams).forEach(key => {
      returnDiv.push(
        <div
        key={`filterPill${key}`}
        className='filterPill'
        >
          <div
          className='deleteFilter'
          onClick={() => this.deleteParam(key)}
          >
            <Icon icon='remove'/>
          </div>
          <div
          className='filterKey'
          >
            <Typography>
              {capitalizeAll(
                key.replace('links_dp_', '')
              )}
            </Typography>
          </div>
          <div
          className='filterValue'
          >
            <Typography>
              <FilterPillValue
              domain={this.props.domain}
              nameKey={key}
              value={queryParams[key]}
              />
            </Typography>
          </div>
          <div className='clear'/>
        </div>
      );
    });

    return (
      <Card
      title='Filters'
      >
        {returnDiv}
        <div className='clear' />
      </Card>
    );
  }

  render() {
    const queryParams = this.getQueryParams();

    let returnDiv = [];

    if (queryParams) {
      returnDiv.push(
        this.generateFilterPills(
          queryParams
        )
      );
    }

    return returnDiv;
  }
}

export default withRouter(
  QueryFilters
);