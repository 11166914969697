import React from 'react';
import ReactDOM from 'react-dom';

import './Assets/Stylesheets/index.scss';
import './Assets/Stylesheets/index.css';
import './Assets/Stylesheets/react-resizeable-grid.css';

import Router from './router';

ReactDOM.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>,
  document.getElementById('root')
);
