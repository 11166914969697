import React, { Component } from 'react';

import DefaultTemplate from '../../Templates/defaultTemplate';

export default class WelcomePage extends Component {
  state = {
    selectedPage: false
  };

  render() {
    return (
      <DefaultTemplate>
        TODO: Add 404
      </DefaultTemplate>
    );
  }
}
