import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { get } from 'superagent';

import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import TocIcon from '@material-ui/icons/Toc';
import Accordion from '@material-ui/core/Accordion';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { capitalizeAll } from '../../Utils/string';
import { getQueryParams } from '../../Utils/query';

import Paper from '../Atoms/paper';
import Loader from '../Atoms/loader';
import TableTypeIcon from '../Atoms/Icons/tableTypeIcon';

class DataModelDomainExplorerMap extends Component {
  state = this.initialState();

  constructor(props) {
    super(props);

    this.getQueryParams = getQueryParams.bind(this);
  }

  initialState() {
    return {
      componentWidth: 0,
      componentHeight: 0,
      model: {},
      loading: true
    };
  }

  componentDidMount() {
    this.init(
      this.props
    )
  }

  componentWillReceiveProps(nextProps) {
    this.setState(
      this.initialState(),
      () => this.init(nextProps)
    );
  }

  init(props) {
    const {
      domain
    } = props;

    const {
      REACT_APP_MODEL_API
    } = process.env;

    get(`${REACT_APP_MODEL_API}/explore/${domain}`).end((err, res) => {
      if (err) {

        return;
      }

      this.setState({
        model: res.body,
        componentWidth: window.innerWidth,
        componentHeight: window.innerHeight,
      }, () => this.setState({ loading: false }));
    });
  }

  render() {
    let {
      model,
      loading,
    } = this.state;
    let {
      domain,
      group,
      style
    } = this.props;
    const collectTables = [];
    const params = this.getQueryParams();

    if (params && params.navigator) {
      style = {
        opacity: '0.3',
        filter: 'blur(10px)'
      }
    }

    if (loading) {
      return <Loader />;
    }

    Object.keys(model).forEach(key => {
      const {
        // config,
        attributes
      } = model[key];
      const detailedAttributes = attributes[group];

      Object.keys(detailedAttributes).forEach(table => {

        collectTables.push(
          <Accordion
          key={`${group}_${table}`}
          defaultExpanded
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${group}_${table}_content`}
              id={`${group}_${table}_id`}
            >
              <TableTypeIcon style={{ marginRight: '10px' }} tableType={group} />
              <Typography variant="caption" style={{ padding: '3px 0 2px' }} >
                {capitalizeAll(domain)} {capitalizeAll(group)} {capitalizeAll(table)}
              </Typography>
            </AccordionSummary>
               Show Records
               <TocIcon
               onClick={() => this.props.history.push(`/explore/${domain}/${group}/${table}`)}
               />
          </Accordion>
        );
      });
    });

    return <Grid
    key={`grid_Paper_${domain}ExplorerMapDetailed_${group}`}
    ref={(div) => { this[`location_grid_${domain}`] = div }}
    item xs={12}
    sm={12}
    style={style}
    >
      <Paper>
        <CardHeader
        avatar={
          <Avatar style={{ backgroundColor: 'transparent' }}>
            <TableTypeIcon tableType={group} style={{ color: '#ffffff5e' }} />
          </Avatar>
        }
        title={`${capitalizeAll(domain)} ${capitalizeAll(group)}`}
        />
        {collectTables}
      </Paper>
    </Grid >
  }
}

export default withRouter(DataModelDomainExplorerMap);
