
import React from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '../../Atoms/grid';
import KpiNumber from '../../Atoms/kpiNumber';
import Card from '../../Molecules/card';
import DataTable from '../../Molecules/dataTable';

function LogDashboard() {
  const rootstyle = {
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    boxSizing: 'border-box',
  }

  const dataTypeMapping = {
    sync_finished_ats: 'dateTime',
    sync_started_ats: 'dateTime',
    sync_inserted_records: 'integer',
    sync_running_durations: 'integer',
    sync_messages: 'info',
    executor_names: 'sideInfo',
    synced_models: 'sideInfo',
    executor_ips: 'ip',
  };

  return (
    <div style={rootstyle}>
      <Grid container>
        <Grid
          columns={2}
          spacing={10}
        >
          <Card
            title='Executors'
            description='counts all executors'
          >
            <KpiNumber
              countDomain='log-dp-links-dp-executor-names/count'
              title='executors'
            />
          </Card>
        </Grid>
        <Grid
          columns={2}
          spacing={10}
        >
          <Card
            title='Synced Models'
            description='counts all synced models'
          >
            <KpiNumber
              countDomain='log-dp-links-dp-synced-models/count'
              title='synced models'
            />
          </Card>
        </Grid>
        <Grid
          columns={12}
          spacing={10}
        >
          <Card
            title='Logs Count'
            description='counts all logs'
          >
            <KpiNumber
              countDomain='log-dp-transactions-dp-totals/count'
              title='Logs'
            />
          </Card>
        </Grid>
        <Grid
          columns={12}
          spacing={10}
        >
          <Card
          title='Log History'
          description='This table shows the latest logs from the data processor'
          >
            <DataTable
            dataTypeMapping={dataTypeMapping}
            url={'views-log/all'}
            />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default withRouter(
  LogDashboard
);