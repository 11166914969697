import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import '../../Assets/Stylesheets/_eye.scss';

class HomePageEye extends Component {
  state = {
    alarm: false,
    loginModal: false
  };

  switchAlarm() {
    const {
      alarm,
      loginModal
    } = this.state;

    this.setState({
      alarm: !alarm
    }, () => {
      if(!alarm) {
        this.setState({
          loginModal: true
        });
      }
    });
  }

  render() {
    let alarm = '';
    let loginModal = null;

    if (this.state.alarm) {
      alarm = 'alarm'
    }

    if (this.state.loginModal) {
      loginModal = <div style={{position: 'fixed'}} className='loginModal'>
        Please Authenticate
      </div>
    }

    return <Fragment>
      {loginModal}
      <div className="eyeholder" onClick={() => this.switchAlarm()}>
        <div className="eye">
          <div className={`eyeball ${alarm}`}>
            <div className="iris">
              <div className="iris1">
                <div className="blackball">
                  <div className="ballshine" />
                </div>
                <div className="shine" />
                <div className={`effect1 ${alarm}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>;
  }  
}

export default withRouter(HomePageEye);
