import React, { Component } from 'react';
import { get } from 'superagent';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import ToggleButton from '@material-ui/lab/ToggleButton';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LocationOffIcon from '@material-ui/icons/LocationOff';

import {
  getQueryParams,
  setQueryParam,
  deleteAllParams
} from '../../../Utils/query';
import {
  capitalize
} from '../../../Utils/string';

import Paper from '../../Atoms/paper';
import TableTypeIcon from '../../Atoms/Icons/tableTypeIcon';
import NavigatorMap from '../../Molecules/navigatorMap';

class ModalConfigDataModel extends Component {
  constructor(props) {
    super(props);

    this.setQueryParam = setQueryParam.bind(this);
    this.getQueryParams = getQueryParams.bind(this);
    this.deleteAllParams = deleteAllParams.bind(this);
  }

  state = {
    model: {},
    loading: true
  };

  componentDidMount() {
    this.init(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.init(nextProps);
  }

  init(props) {
    const {
      REACT_APP_MODEL_API
    } = process.env;

    get(
      `${REACT_APP_MODEL_API}/explore/${props.currentDomain}/${props.currentGroup}`
    ).end((err, res) => {
      if (err) {

        return;
      }
    
      this.setState({
        model: res.body.apis,
        domain: props.currentDomain,
        group: props.currentGroup,
        loading: false
      });
    });
  }

  switchNavigator() {
    const params = this.getQueryParams();

    if (params.navigator) {
      return this.deleteAllParams();
    }

    this.setQueryParam({ navigator: true });
  }

  toggleButton() {
    const params = this.getQueryParams();

    return (
      <ToggleButton
        value="check"
        size='small'
        style={{
          position: 'fixed',
          top: 120,
          right: 10,
          zIndex: 99999
        }}
        onChange={() => {
          this.switchNavigator()
        }}
      >
        {params.navigator ?
          <LocationOffIcon /> :
          <LocationOnIcon />
        }
      </ToggleButton>
    );
  }

  render() {
    const {
      loading
    } = this.state;
    const {
      currentDomain,
      currentGroup
    } = this.props;
    const params = this.getQueryParams();

    if (!params) {
      return this.toggleButton();
    }

    return <Grid
      item
      xs={12}
      style={{
        position: 'fixed',
        top: 80,
        // height: '2000px',
        left: 0,
        zIndex: 99999
      }}
    >
      <Paper>
        <CardHeader
          style={{ color: 'white' }}
          avatar={
            <Avatar style={{ backgroundColor: 'transparent' }}>
              <TableTypeIcon tableType={currentGroup} style={{ color: '#ffffff5e' }} />
            </Avatar>
          }
          title={capitalize('navigator')}
        />

        {loading ? null : <NavigatorMap style={{opacity: '0.4'}} top={250} radius={100} position='center' domain={'devices'} group='links'/>}
        {loading ? null : <NavigatorMap style={{opacity: '0.4'}} top={250} radius={100} position='right' domain={'health'} group='links'/>}
        {loading ? null : <NavigatorMap style={{opacity: '0.4'}} top={250} radius={100} position='left' domain={'banking'} group='links'/>}
        
        
        {loading ? null : <NavigatorMap top={550} radius={100} position='center' domain={currentDomain} group='links'/>}
        {loading ? null : <NavigatorMap top={550} radius={150} position='left' domain={currentDomain} group={['devices', 'web'].includes(currentDomain) ? 'metrics' : 'transactions'}/>}
        {loading ? null : <NavigatorMap top={550} radius={150} position='right' domain={currentDomain} group='infos'/>}
        
        
        {loading ? null : <NavigatorMap style={{opacity: '0.4'}} top={850} radius={100} position='center' domain={'devices'} group='links'/>}
        {loading ? null : <NavigatorMap style={{opacity: '0.4'}} top={850} radius={100} position='right' domain={'health'} group='links'/>}
        {loading ? null : <NavigatorMap style={{opacity: '0.4'}} top={850} radius={100} position='left' domain={'banking'} group='links'/>}

        <div style={{
          height: '900px',
        }}></div>
      </Paper>
      {this.toggleButton()}
    </Grid>;
  }
}

export default withRouter(ModalConfigDataModel);