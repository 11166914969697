function capitalize(string) {
  if (typeof string !== 'string') {
    return '';
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
}


function startsWith(string, startsWith) {
  if (string.startsWith(startsWith)) {
    return string;
  }

  return false;
}

function capitalizeAll(string) {
  if (typeof string !== 'string') {
    return '';
  }

  const returnString = [];

  string.split('_').forEach(word => {
    returnString.push(
      word.charAt(0).toUpperCase() + word.slice(1)
    );
  });

  return returnString.join(' ');
}

function beautifyColumnName(string) {
  if (typeof string !== 'string') {
    return '';
  }

  return capitalizeAll(string.replace('links_dp_', ''));
}

export {
  beautifyColumnName,
  capitalizeAll,
  capitalize,
  startsWith
}