import React, { Component } from 'react';
import { withRouter } from 'react-router';
import superagent from 'superagent';

import {
  setQueryParam,
  getQueryParams
} from '../../Utils/query';

import '../../Assets/Stylesheets/_table.scss';



export default class FilterPillValue extends Component {
  state = {
    value: null,
    loading: true,
    error: false,
  };

  componentDidMount() {
    this.loadData(this.props);
  }

  componentWillUpdate(nextProps) {
    if (this.props !== nextProps) {
      this.loadData(nextProps);
    }
  }

  loadData(props) {
    const {
      REACT_APP_PRESENTER_API
    } = process.env;
    const {
      nameKey,
      value
    } = props;

    if ([ '_start', '_limit', '_sort' ].includes(nameKey)) {
      this.setState({
        value: value,
        loading: false,
        error: false
      });

      return;
    }

    const infoObject = this.getInfoName();

    let jsonWebToken = localStorage.getItem('jsonWebToken');

    if (!jsonWebToken) {
      this.setState({
        loading: false,
        error: 'You are not logged in....'
      });
    }

    if (jsonWebToken) {
      superagent.get(`${REACT_APP_PRESENTER_API}/${infoObject.infoApiUrl}?${nameKey}=${value}&_limit=1`)
      .set('Authorization', `Bearer ${jsonWebToken}`)
      .end((err, res) => {
        if (err) {
          this.setState({
            error: err.message,
            loading: false
          });

          return;
        }

        this.setState({
          value: res.body[0][infoObject.infoKeyName],
          loading: false,
          error: false
        });
      });
    }
  }

  getInfoName() {
    const {
      nameKey,
      domain
    } = this.props;
    let infoKeyName = false;
    let infoApiName = false;
    let infoApiNamePlural = false;

    if (nameKey) {
      infoKeyName = nameKey.split('links_dp_')[1];
    }

    if (infoKeyName) {
      infoApiName = infoKeyName.split('_')[0];
    }

    if (infoApiName) {
      infoApiNamePlural = `${infoApiName}s`;
    }

    return {
      infoKeyName,
      infoApiName,
      infoApiNamePlural,
      infoApiUrl: `${domain}-dp-infos-dp-${infoApiNamePlural}`
    };
  }

  render() {
    const {
      value
    } = this.state;

    return value;
  }
}