import React, { Component } from 'react';
import { get } from 'superagent';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import {
  capitalize,
  startsWith
} from '../../Utils/string';
import { asCircle } from '../../Utils/arrangeItems';

import Loader from '../Atoms/loader';
import Icon from '../Atoms/icon';
import DomainDot from '../Molecules/domainDot';
import ErrorModal from '../Molecules/Modals/errorModal';
import ColumnActionsButton from '../Molecules/Buttons/Navigator/columnActionsButton';

class NavigatorMap extends Component {
  state = {
    componentWidth: 0,
    componentHeight: 0,
    model: {},
    loading: true,
    selectedGroup: null,
    selectedDomain: null,
    selectedLinks: [],
    selectedTransactions: [],
    selectedInfos: [],
    selectedMetrics: [],
    error: false,
  };

  set(information) {
    const {
      domain,
      group
    } = this.props;
    const state = this.state;
    const existingInformation = state[`selected${capitalize(group)}`];
    const selectedLinks = state.selectedLinks;
    const link = startsWith(information, 'links_dp_');

    if (link) {
      selectedLinks.push(link)
    }

    if (!link) {
      existingInformation.push(information);
    }

    this.setState({
      selectedGroup: group,
      [`selected${capitalize(group)}`]: existingInformation,
      selectedDomain: domain,
      selectedLinks: selectedLinks
    });
  }

  componentDidMount() {
    this.init(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.init(nextProps);
  }

  init(props) {
    const {
      domain,
      group,
    } = props;
    const {
      REACT_APP_MODEL_API
    } = process.env;

    get(`${REACT_APP_MODEL_API}/explore/${domain}/${group}`).end((err, res) => {
      if (err) {
        this.setState({
          error: 'is your data model running?'
        });

        return;
      }

      this.setState({
        model: res.body.apis,
        componentWidth: window.innerWidth,
        componentHeight: window.innerHeight,
      }, () => this.setState({ loading: false }));
    });
  }

  render() {
    let {
      model,
      loading,
      componentWidth,
      componentHeight,
      selectedLinks,
      selectedTransactions,
      selectedInfos,
      selectedMetrics,
      error
    } = this.state;

    if(error) {
      return <ErrorModal
      errorMessage={`Error: 😒 ${error}`}
      />;
    }

    if (loading) {
      return <Loader />;
    }

    const returnDivs = [];
    const connectorLines = [];
    const {
      links,
      // linksWith,
      others
    } = model;
    let {
      domain,
      group,
      position,
      radius,
      top,
      style
    } = this.props;
    const outerElementsDiameter = 30;
    const innerElementDiameter = 60;

    if (!radius) {
      radius = 200;
    }

    const circlePositionsOuter = asCircle(
      links,
      radius,
      outerElementsDiameter,
      innerElementDiameter,
      componentWidth,
      componentHeight,
      position,
      top
    );
    const circlePositionsInner = asCircle(
      others,
      70,
      30,
      innerElementDiameter,
      componentWidth,
      componentHeight,
      position,
      top
    );

    let incrementerInner = 0;
    let incrementer = 0;

    if (Object.keys(others).length > 0) {
      Object.keys(others).forEach(other => {
        const circleElementPositionInner = circlePositionsInner.circleElements[incrementerInner];
        const lineElementPositionInner = circlePositionsInner.lineElements[incrementerInner];

        returnDivs.push(
          <ColumnActionsButton
            key={`columnAction_${other}`}
            position={{
              top: `${circleElementPositionInner.positionTop - 40}px`,
              left: `${circleElementPositionInner.positionLeft}px`,
              width: `${circleElementPositionInner.elementWidth}px`,
              height: `${circleElementPositionInner.elementHeight}px`,
            }}
            data={others[other]}
            title={other}
            alignment={circleElementPositionInner.aligned}
            group={group}
          />
        );

        connectorLines.push(<line
          key={`${other}_line_${Math.random()}`}
          x1={lineElementPositionInner.positionLeftStartPoint}
          y1={lineElementPositionInner.positionTopStartPoint - 40}
          x2={lineElementPositionInner.positionLeftEndPoint}
          y2={lineElementPositionInner.positionTopEndPoint - 40}
          stroke="white"
        />);

        incrementerInner++;
      });
    }

    Object.keys(links).forEach(link => {
      const circleElementPositionOuter = circlePositionsOuter.circleElements[incrementer];
      const lineElementPositionOuter = circlePositionsOuter.lineElements[incrementer];

      returnDivs.push(
        <ColumnActionsButton
          key={`LinkAction_${link}`}
          position={{
            top: `${circleElementPositionOuter.positionTop - 40}px`,
            left: `${circleElementPositionOuter.positionLeft}px`,
            width: `${circleElementPositionOuter.elementWidth}px`,
            height: `${circleElementPositionOuter.elementHeight}px`,
          }}
          data={links[link]}
          title={link}
          alignment={circleElementPositionOuter.aligned}
          group={'links'}
        />
      );

      connectorLines.push(<line
        key={`${link}_line_${Math.random()}`}
        x1={lineElementPositionOuter.positionLeftStartPoint}
        y1={lineElementPositionOuter.positionTopStartPoint - 40}
        x2={lineElementPositionOuter.positionLeftEndPoint}
        y2={lineElementPositionOuter.positionTopEndPoint - 40}
        stroke="black"
      />);

      incrementer++;
    });

    return <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={style}
    >
      <Grid style={{ textAlign: 'center' }} item xs={3}>

        {/* {selectedDomain}
        {' _dp_ '}
        {selectedGroup}
        {' _dp_ '}*/ }
        {selectedTransactions.map(hello => <div>{hello}</div>)}
        {selectedMetrics.map(hello => <div>{hello}</div>)}

      </Grid>
      <Grid style={{
        textAlign: 'center',
        lineBreak: 'anywhere'
      }} item xs={6}>

        {returnDivs}

        <DomainDot
          top={circlePositionsOuter.centricElement.positionTop - 40}
          left={circlePositionsOuter.centricElement.positionLeft}
          width={circlePositionsOuter.centricElement.elementWidth}
          height={circlePositionsOuter.centricElement.elementHeight}
          icon={<Icon key={`${domain}_domain_icon_centric`} icon={domain} />}
          type='centric'
          key={`centric_circle_${Math.random()}`}
          description={'Please select a Domain'}
          style={{
            ...{
              boxShadow: '48px 53px 15px rgba(0, 0, 0, 0.05)',
              background: 'white'
            },
          }}
        />
        <svg style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 0,
        }}
          key={`svg_${Math.random()}`}
        >
          {connectorLines}
        </svg>
        {selectedLinks.map(hello => <div>{hello}</div>)}
      </Grid>
      <Grid style={{ textAlign: 'center' }} item xs={3}>
        {/* {selectedDomain}
        {' _dp_ '}
        {selectedGroup}
        {' _dp_ '}
        {selectedInfos} */}
        {selectedInfos.map(hello => <div>HEY {hello}</div>)}

      </Grid>
    </Grid>
  }
}

export default withRouter(NavigatorMap);
