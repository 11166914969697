
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { getQueryParams } from '../../../Utils/query';

import AppHeading from '../../Atoms/appHeading';
import BreadCrumbBar from '../../Molecules/breadCrumbBar';
import ModelNavigator from '../../Molecules/Modals/modelNavigator';
import TopDotNavigation from '../../Molecules/topDotNavigation';
import ExploreDomainGroupTable from '../../Organisms/exploreDomainGroupTable';
import DefaultTemplate from '../../Templates/defaultTemplate';

class DatamodelDomainExplorerGroup extends Component {
  constructor(props) {
    super(props);

    this.getQueryParams = getQueryParams.bind(this);
  }

  state = {
    model: {},
    loading: true
  };

  render() {
    const {
      match,
      location
    } = this.props;

    const {
      params
    } = match;

    const param = this.getQueryParams();
    let style = {};

    if (param.navigator) {
      style = {
        filter: 'blur(10px)'
      };
    }

    return (
      <DefaultTemplate
        blurred
      >
        <TopDotNavigation
        location={location}
        currentDomain={params.domain}
        currentGroup={params.group}
        />
        <AppHeading
        style={style}
        text={`${params.domain} ${params.group}`}
        />
        <BreadCrumbBar
        pageName='Explores'
        domain={params.domain}
        url='explore'
        />
        <ExploreDomainGroupTable
        domain={params.domain}
        group={params.group}
        table={params.table}
        />
        <ModelNavigator
        currentDomain={params.domain}
        currentGroup={params.group}
        />
      </DefaultTemplate>
    );
  }
}

export default withRouter(
  DatamodelDomainExplorerGroup
);
